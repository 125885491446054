import { combineReducers } from 'redux';
import { authReducer, AuthReducerState } from '@core/store/reducers/AuthReducer';
import { checkoutReducer, CheckoutReducerState } from '@core/store/reducers/CheckoutReducer';
import { paymentsReducer, PaymentsReducerState } from '@core/store/reducers/PaymentsReducer';
import { loadingReducer, LoadingReducerState } from '@core/store/reducers/LoadingReducer';
import { errorsReducer, ErrorsReducerState } from '@core/store/reducers/ErrorsReducer';
import { successReducer, SuccessReducerState } from '@core/store/reducers/SuccessReducer';
import { localeReducer, LocaleReducerState } from '@core/store/reducers/LocaleReducer';

export interface AppState {
    auth: AuthReducerState;
    checkout: CheckoutReducerState;
    payments: PaymentsReducerState;
    loading: LoadingReducerState;
    success: SuccessReducerState;
    errors: ErrorsReducerState;
    locale: LocaleReducerState;
}

export const reducer = combineReducers<AppState>({
    auth: authReducer,
    checkout: checkoutReducer,
    payments: paymentsReducer,
    success: successReducer,
    loading: loadingReducer,
    errors: errorsReducer,
    locale: localeReducer
});
