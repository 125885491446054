import { AuthReducerState } from '@core/store/reducers/AuthReducer';
import { VerifyConfirmationEmailTokenResponse } from '@core/store/effects/User';

export const verifyConfirmationEmailTokenSuccess = (
    state: AuthReducerState,
    payload: VerifyConfirmationEmailTokenResponse
): AuthReducerState => {
    const { data } = payload;
    return {
        ...state,
        ...(state.user &&
            data && {
                user: {
                    ...state.user,
                    emailVerified: data.emailVerified
                }
            })
    };
};
