import { AppRoute } from '@core/common/routes';
import { WhatToKnow } from './WhatToKnow';
import { Paths } from '@core/common/paths';

export const whatToKnowRoute: AppRoute[] = [
    {
        path: Paths.resources.whattoknow,
        exact: true,
        component: WhatToKnow
    }
];
