export const mapBarcodeQuantityToPrice = (quantity: number): number | null => {
    switch (quantity) {
        case 1: {
            return 3495;
        }
        case 3: {
            return 7495;
        }
        case 5: {
            return 10995;
        }
        case 10: {
            return 16995;
        }
        case 25: {
            return 23995;
        }
        case 50: {
            return 30995;
        }
        case 100: {
            return 47995;
        }
        case 150: {
            return 62995;
        }
        case 200: {
            return 71995;
        }
        // Test purposes
        case 2: {
            return 100;
        }
        default:
            return null;
    }
};
