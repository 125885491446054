import { AuthReducerState } from '@core/store/reducers/AuthReducer';
import { ChangeEmailResponse } from '@core/store/effects/User';

export const changeEmailSuccess = (state: AuthReducerState, payload: ChangeEmailResponse): AuthReducerState => {
    const { data } = payload;
    return {
        ...state,
        ...(state.user &&
            data && {
                user: {
                    ...state.user,
                    email: data.email,
                    emailVerified: data.emailVerified
                }
            })
    };
};
