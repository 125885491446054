import { AppRoute } from '@core/common/routes';
import { Home } from './Home';
import { Paths } from '@core/common/paths';

export const homeRoute: AppRoute[] = [
    {
        path: Paths.main.home,
        exact: true,
        component: Home
    },
    {
        path: `/au${Paths.main.home}`,
        exact: true,
        component: Home
    }
];
