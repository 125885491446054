import { AuthReducerState } from '@core/store/reducers/AuthReducer';
import { ToggleAuthResponse } from '@core/store/effects/Auth';

export const toggleAuthSuccess = (state: AuthReducerState, payload: ToggleAuthResponse): AuthReducerState => {
    const { view } = payload;
    return {
        ...state,
        requireAuth: !view
            ? undefined
            : {
                  show: true,
                  view
              }
    };
};
