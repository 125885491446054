import React from 'react';
import style from './Accordion.scss';
import { Icon } from '../Icon';

interface ComponentProps {
    id: string;
    open: string;
    title: string;
    onToggle: (id: string) => void;
}

const AccordionItem: React.FC<ComponentProps> = ({ id, open, title, onToggle, children }) => {
    return (
        <div className={style.item} id={id}>
            <div className={style.iHead} onClick={() => onToggle(id)}>
                <div className={style.iTitle}>{title}</div>
                <div className={style.iToggle}>
                    {open !== id ? (
                        <Icon name="plus" size="12px" color="#222" />
                    ) : (
                        <Icon name="minus" size="12px" color="#222" />
                    )}
                </div>
            </div>
            {open === id && <div className={style.iBody}>{children}</div>}
        </div>
    );
};

export { AccordionItem };
