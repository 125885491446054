import React from 'react';
import { Icon } from '@core/ui/atoms/Icon';
import style from './GlobalError.scss';
import { ApiErrors } from '@core/interface/errors';

interface Props {
    errors: ApiErrors;
}

const GlobalError: React.FC<Props> = ({ errors }) =>
    !Object.keys(errors).length ? null : (
        <div className={style.globalError}>
            <span>
                <Icon name="exclamation-triangle" />
            </span>
            {Object.values(errors).map(error => {
                return (
                    <div key={error.code} className={style.message}>
                        {error.message}
                    </div>
                );
            })}
        </div>
    );

export { GlobalError };
