import { AppRoute } from '@core/common/routes';
import { AccountPayments } from './AccountPayments';
import { Paths } from '@core/common/paths';
import { FetchUserPayments } from '@core/store/effects/Payments';

export const accountPaymentsRoute: AppRoute[] = [
    {
        path: Paths.account.payments,
        exact: true,
        component: AccountPayments,
        preloadActions: [FetchUserPayments]
    }
];
