import { Dispatch } from 'redux';
import { AppApi } from '@core/api';
import { AppState } from '@core/common/reducer';
import { FetchCurrentUserTypes } from './Types';
import { FetchCurrentUserResponse } from './Interface';

export const FetchCurrentUser = () => {
    return async (dispatch: Dispatch, getState: () => AppState, { requestApi }: AppApi) => {
        try {
            dispatch({
                type: FetchCurrentUserTypes.FETCH_CURRENT_USER_REQUEST
            });
            const res = await requestApi.get<FetchCurrentUserResponse>('/user/current');
            dispatch({
                type: FetchCurrentUserTypes.FETCH_CURRENT_USER_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: FetchCurrentUserTypes.FETCH_CURRENT_USER_FAILURE,
                payload: err.response.data
            });
        }
    };
};
