import { AppRoute } from '@core/common/routes';
import { Contact } from './Contact';
import { Paths } from '@core/common/paths';

export const contactRoute: AppRoute[] = [
    {
        path: Paths.main.contact,
        exact: true,
        component: Contact
    }
];
