import { Dispatch } from 'redux';
import { AppApi } from '@core/api';
import { AppState } from '@core/common/reducer';
import { VerifyConfirmationEmailTokenTypes } from './Types';
import { VerifyConfirmationEmailTokenPayload, VerifyConfirmationEmailTokenResponse } from './Interface';

const VerifyConfirmationEmailToken = (data: VerifyConfirmationEmailTokenPayload) => {
    return async (dispatch: Dispatch, getState: () => AppState, { requestApi }: AppApi) => {
        try {
            dispatch({
                type: VerifyConfirmationEmailTokenTypes.VERIFY_CONFIRMATION_EMAIL_TOKEN_REQUEST
            });
            const res = await requestApi.post<VerifyConfirmationEmailTokenResponse>('/user/verify/email/token', data);
            dispatch({
                type: VerifyConfirmationEmailTokenTypes.VERIFY_CONFIRMATION_EMAIL_TOKEN_SUCCESS,
                payload: res.data
            });

            return { success: true };
        } catch (err) {
            dispatch({
                type: VerifyConfirmationEmailTokenTypes.VERIFY_CONFIRMATION_EMAIL_TOKEN_FAILURE,
                payload: err.response.data
            });

            return { success: false };
        }
    };
};

export { VerifyConfirmationEmailToken };
