import { Reducer } from 'redux';
import { LoadingReducerState } from './Interface';

const initialState: LoadingReducerState = {};

interface AnyAction {
    type: string;
}

export const loadingReducer: Reducer<LoadingReducerState, AnyAction> = (
    state = initialState,
    action
): LoadingReducerState => {
    const { type } = action;
    const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

    // If it's not a *_REQUEST / *_FAILURE action, ignore
    if (!matches) {
        return state;
    }

    const [, requestName, requestState] = matches;

    return {
        ...state,
        [requestName]: requestState === 'REQUEST'
    };
};
