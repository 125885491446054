/**
 * Takes a timestamp and return the amount of days from now.
 * @param timestamp Unix timestamp.
 */
export const getDaysFromTimestamp = (timestamp: number): number => {
    // 1 day in milliseconds
    const oneDay = 24 * 60 * 60 * 1000;

    // Get now
    const now = new Date().getTime();

    // Return the difference in days
    return Math.round(Math.abs((now - timestamp) / oneDay));
};
