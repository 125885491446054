/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Paths } from '@core/common/paths';
import { AuthReducerState } from '@core/store/reducers/AuthReducer';

interface Props {
    auth: AuthReducerState;
}

const PublicRoute = <P extends object>(ChildComponent: React.FC<P> | React.ComponentType<P>) => {
    const PublicRouteInternal: React.FC<P & Props> = ({ auth }, props) => {
        return auth.isAuthenticated ? <Redirect to={Paths.account.root} /> : <ChildComponent {...props} />;
    };

    const mapStateToProps = ({ auth }: Props) => {
        return { auth };
    };

    return connect(mapStateToProps)(PublicRouteInternal as React.FC<Anything>);
};

export { PublicRoute };
