import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Api from '@core/store/effects';
import { Paths } from '@core/common/paths';
import { FormField } from '@core/ui/atoms/FormField';
import { Button } from '@core/ui/atoms/Button';
import { Loading } from '@core/ui/atoms/Loading';
import { CircularIcon } from '@core/ui/atoms/CircularIcon';
import style from './ResetPasswordForm.scss';
import main from '@core/assets/styles/global.scss';
import { StateProps, DispatchProps, AllProps, FormData, FormErrors } from './ResetPasswordForm.interface';
import { AppState } from '@core/common/reducer';
import { createLoadingSelector } from '@core/store/selectors/request/loadingSelector';
import { createSuccessSelector } from '@core/store/selectors/request/successSelector';
import { createErrorMessageSelector } from '@core/store/selectors/request/errorsSelector';
import { VerifyPasswordResetCodeTypes, ResetPasswordTypes } from '@core/store/effects/Auth';
import { FormWrapper } from '../../global/FormWrapper';

const ResetPasswordFormInternal: React.FC<AllProps> = ({
    requestResetPassword,
    verifyPasswordResetCode,
    tokenInFlight,
    tokenErrors,
    tokenSuccess,
    resetInFlight,
    resetErrors,
    resetSuccess
}) => {
    const [data, setData] = React.useState<FormData>({
        code: '',
        password: '',
        confirmPassword: ''
    });
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<FormErrors>({});

    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');

        if (code) {
            // Verify the code
            verifyPasswordResetCode({ code });

            // Set it in state for the reset request
            setData({ ...data, code });
        }
    }, []);

    // Listen to when verifyPasswordResetCode completes and stop loading state
    React.useEffect(() => {
        setLoading(false);
    }, [tokenInFlight]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    const validate = (): FormErrors => {
        const formErrors: FormErrors = {};

        if (!data.password) {
            formErrors.password = 'Password is required';
        } else if (data.password !== data.confirmPassword) {
            formErrors.confirmPassword = 'Passwords do not match';
        } else if (data.password.length < 8) {
            formErrors.password = 'Password must be at least 8 characters';
        }

        return formErrors;
    };

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const formErrors = validate();
        setErrors(formErrors);
        if (Object.keys(formErrors).length === 0) {
            requestResetPassword(data);
        }
    };

    return (
        <FormWrapper>
            <div className={style.component}>
                {loading || tokenInFlight ? (
                    <Loading color="PRIMARY" size={10} />
                ) : (
                    <>
                        {!tokenSuccess ? (
                            <div className={style.invalidToken}>
                                <CircularIcon icon="exclamation-triangle" />
                                <h4>Token Invalid</h4>
                                <p>
                                    <span>
                                        The link used to reset your password is either invalid or has expired. Please
                                        request a{' '}
                                    </span>
                                    <Link to={Paths.auth.forgotPassword}>new link</Link>
                                    <span> or </span>
                                    <Link to={Paths.main.contact}>contact support</Link>
                                    <span>.</span>
                                </p>
                            </div>
                        ) : (
                            <>
                                {!resetSuccess ? (
                                    <form onSubmit={onSubmit}>
                                        <CircularIcon icon="lock-alt" />
                                        <h1 className={main.h4}>Reset Password</h1>
                                        <FormField
                                            type="password"
                                            name="password"
                                            placeholder="New password"
                                            value={data.password}
                                            onChange={onChange}
                                            error={errors.password}
                                        />
                                        <FormField
                                            type="password"
                                            name="confirmPassword"
                                            placeholder="Confirm password"
                                            value={data.confirmPassword}
                                            onChange={onChange}
                                            error={errors.confirmPassword}
                                        />
                                        <Button text="Reset Password" loading={resetInFlight} type="submit" extend />
                                    </form>
                                ) : (
                                    <div className={style.resetSuccess}>
                                        <CircularIcon icon="check" />
                                        <h4>Success</h4>
                                        <p>
                                            <span>Your password has been reset successfully. Please </span>
                                            <Link to={Paths.auth.login}>Log in</Link>
                                            <span> to your account.</span>
                                        </p>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </FormWrapper>
    );
};

const tokenLoadingSelector = createLoadingSelector([VerifyPasswordResetCodeTypes.BASE]);
const tokenSuccessSelector = createSuccessSelector([VerifyPasswordResetCodeTypes.BASE]);
const tokenErrorsSelector = createErrorMessageSelector([VerifyPasswordResetCodeTypes.BASE]);
const resetLoadingSelector = createLoadingSelector([ResetPasswordTypes.BASE]);
const resetSuccessSelector = createSuccessSelector([ResetPasswordTypes.BASE]);
const resetErrorsSelector = createErrorMessageSelector([ResetPasswordTypes.BASE]);

const mapStateToProps = (state: AppState): StateProps => ({
    tokenInFlight: tokenLoadingSelector(state),
    tokenSuccess: tokenSuccessSelector(state),
    tokenErrors: tokenErrorsSelector(state),
    resetInFlight: resetLoadingSelector(state),
    resetSuccess: resetSuccessSelector(state),
    resetErrors: resetErrorsSelector(state)
});

const mapDispatchToProps: DispatchProps = {
    requestResetPassword: Api.Auth.RequestResetPassword,
    verifyPasswordResetCode: Api.Auth.VerifyPasswordResetCode
};

const ResetPasswordForm = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordFormInternal);

export { ResetPasswordForm };
