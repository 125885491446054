import React from 'react';
import * as Api from '@core/store/effects';
import { PrivateRoute } from '@core/ui/molecules/hocs/PrivateRoute';
import style from './AccountCertificates.scss';
import { PageTemplate } from '@core/ui/templates';
import { UserModel } from '@core/interface/models/user';
import { AppState } from '@core/common/reducer';
import { connect } from 'react-redux';
import { Container } from '@core/ui/atoms/Container';
import { Loading } from '@core/ui/atoms/Loading';
import { AccountHead } from '@core/ui/molecules/pages/dash/AccountHead';
import { Paths } from '@core/common/paths';
import { createLoadingSelector } from '@core/store/selectors/request/loadingSelector';
import { PaymentsReducerState } from '@core/store/reducers/PaymentsReducer';
import { Button } from '@core/ui/atoms';
import { getDateFromTimestamp } from '@core/utils/date';
import { AccountEmpty } from '@core/ui/molecules/pages/dash/AccountEmpty';

const options = {
    body: {},
    header: {
        include: true,
        theme: 'solid' as 'solid'
    },
    footer: {
        include: true
    },
    seo: {
        title: 'Certificates - BarcodeBee',
        meta: [
            {
                name: 'description',
                content: 'Certificates - BarcodeBee'
            }
        ]
    }
};

interface StateProps {
    user?: UserModel;
    payments: PaymentsReducerState;
    paymentsLoading: boolean;
}

interface DispatchProps {
    fetchUserPayments: () => void;
}

const AccountCertificatesInternal: React.FC<StateProps & DispatchProps> = ({
    user,
    paymentsLoading,
    payments,
    fetchUserPayments
}) => {
    React.useEffect(() => {
        if (!payments.list) {
            fetchUserPayments();
        }
    }, []);

    return (
        <PageTemplate options={options}>
            <div className={style.component}>
                <Container>
                    {!user || paymentsLoading ? (
                        <Loading color="PRIMARY" size={10} />
                    ) : (
                        <>
                            <AccountHead title="Certificates" active={Paths.account.certificates} />
                            {payments.list && payments.list.length ? (
                                <div className={style.listWrap}>
                                    <div className={style.listHead}>
                                        <div className={style.date}>Date</div>
                                        <div className={style.id}>ID</div>
                                    </div>
                                    <div className={style.list}>
                                        {payments.list
                                            .sort((a, b) => b.created - a.created)
                                            .map(p => (
                                                <div key={p.paymentId} className={style.item}>
                                                    <div className={style.left}>
                                                        <div className={style.date}>
                                                            {getDateFromTimestamp(p.created)}
                                                        </div>
                                                        <div className={style.id}>{p.paymentId}</div>
                                                    </div>
                                                    <Button
                                                        openInNewTab
                                                        text={`Download Certificate`}
                                                        link={`${Paths.account.certificateDownload}?identifier=${p.paymentId}`}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ) : (
                                <AccountEmpty text="You do not yet have any certificates" />
                            )}
                        </>
                    )}
                </Container>
            </div>
        </PageTemplate>
    );
};

const loadingSelector = createLoadingSelector([Api.Payments.FetchUserPaymentsTypes.BASE]);

const mapStateToProps = (state: AppState): StateProps => {
    return {
        user: state.auth.user,
        payments: state.payments,
        paymentsLoading: loadingSelector(state)
    };
};

const mapDispatchToProps: DispatchProps = {
    fetchUserPayments: Api.Payments.FetchUserPayments
};

const enhance = connect(mapStateToProps, mapDispatchToProps)(AccountCertificatesInternal);

export const AccountCertificates = PrivateRoute(enhance);
