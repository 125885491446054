import React from 'react';
import { connect } from 'react-redux';
import style from './MobileMenu.scss';
import { Logo } from '@core/ui/atoms';
import { LogoutButton } from '../LogoutButton';
import { Link } from 'react-router-dom';
import { Paths } from '@core/common/paths';
import { AppState } from '@core/common/reducer';

interface StateProps {
    isAuthenticated: boolean;
}

interface ComponentProps extends StateProps {}

const MobileMenuInternal: React.FC<ComponentProps> = ({ isAuthenticated }) => {
    return (
        <div className={style.mobileMenu}>
            <div className={style.logo}>
                <Logo theme="solid" width={150} height={34} sendsHome />
            </div>
            <ul className={style.nav}>
                <li>
                    <Link className={style.navItem} to={Paths.main.home}>
                        <div className={style.link}>Overview</div>
                    </Link>
                </li>
                <li>
                    <Link className={style.navItem} to={Paths.main.pricing}>
                        <div className={style.link}>Pricing</div>
                    </Link>
                </li>
                <li>
                    <Link className={style.navItem} to={Paths.resources.whattoknow}>
                        <div className={style.link}>The FAQ</div>
                    </Link>
                </li>
                <div className={style.divider} />
                {!isAuthenticated ? (
                    <>
                        <li>
                            <Link className={style.navItem} to={Paths.auth.login}>
                                <div className={style.link}>Log in</div>
                            </Link>
                        </li>
                        <li>
                            <Link className={style.navItem} to={Paths.auth.signup}>
                                <div className={style.link}>Sign up</div>
                            </Link>
                        </li>
                    </>
                ) : (
                    <>
                        <li>
                            <Link className={style.navItem} to={Paths.account.root}>
                                <div className={style.link}>My Barcodes</div>
                            </Link>
                        </li>
                        <li>
                            <Link className={style.navItem} to={Paths.account.certificates}>
                                <div className={style.link}>Certificates</div>
                            </Link>
                        </li>
                        <li>
                            <Link className={style.navItem} to={Paths.account.payments}>
                                <div className={style.link}>Payments</div>
                            </Link>
                        </li>
                        <li>
                            <Link className={style.navItem} to={Paths.account.personal}>
                                <div className={style.link}>Account Info</div>
                            </Link>
                        </li>
                        <li>
                            <LogoutButton className={style.navItem}>
                                <span className={style.link}>Log Out</span>
                            </LogoutButton>
                        </li>
                    </>
                )}
            </ul>
        </div>
    );
};

const mapStateToProps = ({ auth }: AppState): StateProps => {
    return {
        isAuthenticated: auth.isAuthenticated
    };
};

export const MobileMenu = connect<StateProps>(mapStateToProps)(MobileMenuInternal);
