import { AppRoute } from '@core/common/routes';
import { AccountCertificateDownload } from './AccountCertificateDownload';
import { Paths } from '@core/common/paths';

export const accountCertificateDownloadRoute: AppRoute[] = [
    {
        path: Paths.account.certificateDownload,
        exact: true,
        component: AccountCertificateDownload
    }
];
