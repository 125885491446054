import { Dispatch } from 'redux';
import { AppApi } from '@core/api';
import { AppState } from '@core/common/reducer';
import { ChangeNameTypes } from './Types';
import { ChangeNamePayload, ChangeNameResponse } from './Interface';

const ChangeName = (data: ChangeNamePayload) => {
    return async (dispatch: Dispatch, getState: () => AppState, { requestApi }: AppApi) => {
        try {
            dispatch({
                type: ChangeNameTypes.CHANGE_NAME_REQUEST
            });
            const res = await requestApi.post<ChangeNameResponse>('/user/update/name', data);
            dispatch({
                type: ChangeNameTypes.CHANGE_NAME_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: ChangeNameTypes.CHANGE_NAME_FAILURE,
                payload: err.response.data
            });
        }
    };
};

export { ChangeName };
