import React from 'react';
import { PageTemplate } from '@core/ui/templates';
import image from '@core/assets/images/not_found.svg';
import style from './NotFound.scss';
import { Button } from '@core/ui/atoms';
import { Paths } from '@core/common/paths';
interface Props {
    staticContext?: {
        statusCode?: number;
    };
}

const options = {
    body: {},
    header: {
        include: false
    },
    footer: {
        include: false
    },
    seo: {
        title: 'Not found',
        meta: [
            {
                name: 'description',
                content: 'Not found page meta description'
            }
        ]
    }
};

const NotFoundInternal = ({ staticContext = {} }: Props) => {
    staticContext.statusCode = 404;

    return (
        <PageTemplate options={options}>
            <div className={style.notFoundWrap}>
                <div className={style.notFoundInner}>
                    <div className={style.imageWrap}>
                        <img src={image} width="370" />
                    </div>
                    <div className={style.contentWrap}>
                        <h1>404</h1>
                        <h3>Page not found</h3>
                        <p>
                            It looks like we couldn&apos;t find what you were looking for. The page requested was not
                            found.
                        </p>
                        <Button link={Paths.main.home} text="Go back home" />
                    </div>
                </div>
            </div>
        </PageTemplate>
    );
};

export const NotFound = NotFoundInternal;
