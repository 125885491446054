import React from 'react';
import { PublicRoute } from '@core/ui/molecules/hocs/PublicRoute';
import { ResetPasswordForm } from '@core/ui/molecules/forms/ResetPasswordForm';
import style from './ResetPassword.scss';
import { PageTemplate } from '@core/ui/templates';
import { Container } from '@core/ui/atoms/Container';

const options = {
    body: {},
    header: {
        include: true,
        theme: 'solid' as 'solid'
    },
    footer: {
        include: true
    },
    seo: {
        title: 'Reset Password - BarcodeBee',
        meta: [
            {
                name: 'description',
                content: 'Reset Password - BarcodeBee'
            }
        ]
    }
};

const ResetPasswordInternal = () => (
    <PageTemplate options={options}>
        <Container>
            <div className={style.component}>
                <ResetPasswordForm />
            </div>
        </Container>
    </PageTemplate>
);

export const ResetPassword = PublicRoute(ResetPasswordInternal);
