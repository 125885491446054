import { Dispatch } from 'redux';
import { AppApi } from '@core/api';
import { AppState } from '@core/common/reducer';
import { ChangeEmailTypes } from './Types';
import { ChangeEmailPayload, ChangeEmailResponse } from './Interface';

const ChangeEmail = (data: ChangeEmailPayload) => {
    return async (dispatch: Dispatch, getState: () => AppState, { requestApi }: AppApi) => {
        try {
            dispatch({
                type: ChangeEmailTypes.CHANGE_EMAIL_REQUEST
            });
            const res = await requestApi.post<ChangeEmailResponse>('/user/update/email', data);
            dispatch({
                type: ChangeEmailTypes.CHANGE_EMAIL_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: ChangeEmailTypes.CHANGE_EMAIL_FAILURE,
                payload: err.response.data
            });
        }
    };
};

export { ChangeEmail };
