import { Dispatch } from 'redux';
import { AppApi } from '@core/api';
import { AppState } from '@core/common/reducer';
import { VerifyPasswordResetCodeTypes } from './Types';
import { VerifyPasswordResetCodePayload, VerifyPasswordResetCodeResponse } from './Interface';

const VerifyPasswordResetCode = (data: VerifyPasswordResetCodePayload) => {
    return async (dispatch: Dispatch, getState: () => AppState, { requestApi }: AppApi) => {
        try {
            dispatch({
                type: VerifyPasswordResetCodeTypes.VERIFY_PASSWORD_RESET_CODE_REQUEST
            });
            const res = await requestApi.post<VerifyPasswordResetCodeResponse>(
                '/auth/verify_password_reset_code',
                data
            );
            dispatch({
                type: VerifyPasswordResetCodeTypes.VERIFY_PASSWORD_RESET_CODE_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: VerifyPasswordResetCodeTypes.VERIFY_PASSWORD_RESET_CODE_FAILURE,
                payload: err.response.data
            });
        }
    };
};

export { VerifyPasswordResetCode };
