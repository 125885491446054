import { Dispatch } from 'redux';
import { AppApi } from '@core/api';
import { AppState } from '@core/common/reducer';
import { AnalyticsRouteTypes } from './Types';
import { AnalyticsRoutePayload, AnalyticsRouteResponse } from './Interface';

const RequestAnalyticsRoute = (data: AnalyticsRoutePayload) => {
    return async (dispatch: Dispatch, getState: () => AppState, { requestApi }: AppApi) => {
        try {
            dispatch({
                type: AnalyticsRouteTypes.ANALYTICS_ROUTE_REQUEST
            });
            const res = await requestApi.post<AnalyticsRouteResponse>('/analytics/route', data);
            dispatch({
                type: AnalyticsRouteTypes.ANALYTICS_ROUTE_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: AnalyticsRouteTypes.ANALYTICS_ROUTE_FAILURE,
                payload: err.response.data
            });
        }
    };
};

export { RequestAnalyticsRoute };
