import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Api from '@core/store/effects';
import { Paths } from '@core/common/paths';
import { FormField } from '@core/ui/atoms/FormField';
import { Button } from '@core/ui/atoms/Button';
import { CircularIcon } from '@core/ui/atoms/CircularIcon';
import { GlobalError } from '@core/ui/atoms/GlobalError';
import style from './SignupForm.scss';
import main from '@core/assets/styles/global.scss';
import { StateProps, DispatchProps, Props, FormData, FormErrors } from './SignupForm.interface';
import { AppState } from '@core/common/reducer';
import { SignupTypes } from '@core/store/effects/Auth';
import { createLoadingSelector } from '@core/store/selectors/request/loadingSelector';
import { createErrorMessageSelector } from '@core/store/selectors/request/errorsSelector';
import { FormWrapper } from '../../global/FormWrapper';
import { config } from '@core/common/config';
import { isEmail } from '@core/utils/validate';
import { createSuccessSelector } from '@core/store/selectors/request/successSelector';

const SignupFormInternal: React.FC<Props> = ({
    requestSignup,
    isFetching,
    requestErrors,
    simplify,
    description,
    toggleAuth,
    requestSuccess
}) => {
    const [data, setData] = React.useState<FormData>({
        firstName: '',
        lastName: '',
        email: '',
        password: ''
    });
    const [errors, setErrors] = React.useState<FormErrors>({});

    React.useEffect(() => {
        if (requestSuccess && simplify) {
            toggleAuth({ step: 2 });
        }
    }, [requestSuccess]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    const validate = (): FormErrors => {
        const formErrors: FormErrors = {};

        if (!isEmail(data.email)) {
            formErrors.email = 'Email address is invalid';
        }
        if (!data.firstName) {
            formErrors.firstName = 'First name is required';
        }
        if (!data.lastName) {
            formErrors.lastName = 'Last name is required';
        }
        if (!data.password) {
            formErrors.password = 'Password is required';
        }

        return formErrors;
    };

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const formErrors = validate();
        setErrors(formErrors);
        if (Object.keys(formErrors).length === 0) {
            requestSignup(data);
        }
    };

    const renderForm = () => {
        return (
            <>
                <form onSubmit={onSubmit} className={style.component}>
                    <CircularIcon icon="lock-alt" />
                    <h1 className={main.h4}>Sign up to continue</h1>
                    {description && <p className={style.description}>{description}</p>}
                    {requestErrors && (
                        <div className={style.globalError}>
                            <GlobalError errors={requestErrors} />
                        </div>
                    )}
                    <FormField
                        type="email"
                        name="email"
                        placeholder="Email address"
                        value={data.email}
                        onChange={onChange}
                        error={errors.email}
                    />
                    <FormField
                        type="text"
                        name="firstName"
                        placeholder="First name"
                        value={data.firstName}
                        onChange={onChange}
                        error={errors.firstName}
                    />
                    <FormField
                        type="text"
                        name="lastName"
                        placeholder="Last name"
                        value={data.lastName}
                        onChange={onChange}
                        error={errors.lastName}
                    />
                    <FormField
                        type="password"
                        name="password"
                        placeholder="Create a password"
                        value={data.password}
                        onChange={onChange}
                        error={errors.password}
                    />

                    <div className={style.callout}>
                        <span>{`By clicking Sign up, I agree to ${config.siteName}'s `}</span>
                        <Link to={Paths.main.terms} target="_blank">
                            Terms of Service
                        </Link>
                        <span> and </span>
                        <Link to={Paths.main.privacy} target="_blank">
                            Privacy Policy
                        </Link>
                        <span>.</span>
                    </div>
                    <Button text="Sign up" loading={isFetching} type="submit" extend />
                </form>
                <div className={style.haveAccount}>
                    <span>Already have an account?</span>
                    {simplify ? (
                        <div className={style.loginLink} onClick={() => toggleAuth({ view: 'log_in' })}>
                            Log in
                        </div>
                    ) : (
                        <Link to={Paths.auth.login}>Log in</Link>
                    )}
                </div>
            </>
        );
    };

    return simplify ? renderForm() : <FormWrapper>{renderForm()}</FormWrapper>;
};

const loadingSelector = createLoadingSelector([SignupTypes.BASE]);
const errorsSelector = createErrorMessageSelector([SignupTypes.BASE]);
const successSelector = createSuccessSelector([SignupTypes.BASE]);

const mapStateToProps = (state: AppState): StateProps => ({
    isFetching: loadingSelector(state),
    requestErrors: errorsSelector(state),
    requestSuccess: successSelector(state)
});

const mapDispatchToProps: DispatchProps = {
    requestSignup: Api.Auth.RequestSignup,
    toggleAuth: Api.Auth.ToggleAuth
};

const SignupForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupFormInternal));

export { SignupForm };
