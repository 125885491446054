import axios, { AxiosResponse } from 'axios';
import { Request } from 'express';

export class RequestApi {
    constructor(private apiUrl: string, private request?: Request) {}

    private axiosInstance = axios.create({
        baseURL: this.apiUrl,
        ...(this.request && {
            headers: {
                cookie: this.request.get('cookie') || ''
            }
        })
    });

    public get<T>(url: string): Promise<AxiosResponse<T>> {
        return this.axiosInstance.get(url);
    }

    public post<T>(url: string, body?: Object): Promise<AxiosResponse<T>> {
        return this.axiosInstance.post(url, body);
    }

    // private createRequest(method: string, url?: string, options?: RequestInit) {
    //     const fullOptions = Object.assign({ method }, this.defaultOptions, options);
    //     const fullUrl = `${this.baseUrl}${url}`;
    //     return fetch(fullUrl, fullOptions).then(res => {
    //         if (res.status !== 200) {
    //             throw Error(`Failed fetch to ${fullUrl}: ${res.status} ${res.statusText}`);
    //         }
    //         return res.json();
    //     });
    // }
}
