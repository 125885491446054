import { Dispatch } from 'redux';
import { AppApi } from '@core/api';
import { AppState } from '@core/common/reducer';
import { ContactTypes } from './Types';
import { ContactPayload, ContactResponse } from './Interface';

const Contact = (data: ContactPayload) => {
    return async (dispatch: Dispatch, getState: () => AppState, { requestApi }: AppApi) => {
        try {
            dispatch({
                type: ContactTypes.CONTACT_REQUEST
            });
            const res = await requestApi.post<ContactResponse>('/user/contact', data);
            dispatch({
                type: ContactTypes.CONTACT_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: ContactTypes.CONTACT_FAILURE,
                payload: err.response.data
            });
        }
    };
};

export { Contact };
