import { Dispatch } from 'redux';
import { AppApi } from '@core/api';
import { AppState } from '@core/common/reducer';
import { PayPalExecutePaymentTypes } from './Types';
import { PayPalExecutePaymentPayload, PayPalExecutePaymentResponse } from './Interface';
import { gtag } from '@core/utils/gtag';
import { config } from '@core/common/config';

const PayPalExecutePayment = (data: PayPalExecutePaymentPayload) => {
    return async (dispatch: Dispatch, getState: () => AppState, { requestApi }: AppApi) => {
        try {
            dispatch({
                type: PayPalExecutePaymentTypes.PAYPAL_EXECUTE_PAYMENT_REQUEST
            });
            const res = await requestApi.post<PayPalExecutePaymentResponse>('/payments/paypal/execute_payment', data);

            // Log GA event
            if (config.env === 'production') {
                gtag('event', 'conversion', {
                    send_to: 'AW-856286973/CEy7COqk2-EBEP3Np5gD',
                    value: res.data.payment ? (res.data.payment.amountInCents / 100).toFixed(2) : 0,
                    currency: 'AUD',
                    transaction_id: res.data.payment ? res.data.payment.paymentId : ''
                });
            }

            dispatch({
                type: PayPalExecutePaymentTypes.PAYPAL_EXECUTE_PAYMENT_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            console.log(err);
            dispatch({
                type: PayPalExecutePaymentTypes.PAYPAL_EXECUTE_PAYMENT_FAILURE,
                payload: err.response.data
            });
        }
    };
};

export { PayPalExecutePayment };
