import { AppRoute } from '@core/common/routes';
import { AccountPersonal } from './AccountPersonal';
import { Paths } from '@core/common/paths';

export const accountPersonalRoute: AppRoute[] = [
    {
        path: Paths.account.personal,
        exact: true,
        component: AccountPersonal
    }
];
