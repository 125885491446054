import { Dispatch } from 'redux';
import { AppApi } from '@core/api';
import { AppState } from '@core/common/reducer';
import { SendConfirmationEmailTypes } from './Types';
import { SendConfirmationEmailResponse } from './Interface';

const SendConfirmationEmail = () => {
    return async (dispatch: Dispatch, getState: () => AppState, { requestApi }: AppApi) => {
        try {
            dispatch({
                type: SendConfirmationEmailTypes.SEND_CONFIRMATION_EMAIL_REQUEST
            });
            const res = await requestApi.post<SendConfirmationEmailResponse>('/user/verify/email');
            dispatch({
                type: SendConfirmationEmailTypes.SEND_CONFIRMATION_EMAIL_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: SendConfirmationEmailTypes.SEND_CONFIRMATION_EMAIL_FAILURE,
                payload: err.response.data
            });
        }
    };
};

export { SendConfirmationEmail };
