import { Reducer } from 'redux';
import * as Api from '../../effects';
import { LocaleReducerState } from './Interface';

const initialState: LocaleReducerState = {
    locale: 'global'
};

type AuthActions = Api.Checkout.PackageChosenAction;

const localeReducer: Reducer<LocaleReducerState, AuthActions> = (state = initialState, action): LocaleReducerState => {
    switch (action.type) {
        default: {
            return state;
        }
    }
};

export { localeReducer };
