import { AuthReducerState } from '@core/store/reducers/AuthReducer';
import { LogoutResponse } from '@core/store/effects/Auth';

export const logoutSuccess = (state: AuthReducerState, payload: LogoutResponse): AuthReducerState => {
    return {
        ...state,
        isAuthenticated: false,
        requireAuth: undefined,
        user: undefined
    };
};
