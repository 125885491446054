export const getDateFromTimestamp = (timestamp: number): string => {
    const date = new Date(timestamp * 1000);

    // Build
    const days = ('0' + date.getDate()).slice(-2);
    const months = ('0' + (date.getMonth() + 1)).slice(-2);
    const years = date.getFullYear();

    return `${days}/${months}/${years}`;
};
