import { AppRoute } from '@core/common/routes';
import { ForgotPassword } from './ForgotPassword';
import { Paths } from '@core/common/paths';

export const forgotPasswordRoute: AppRoute[] = [
    {
        path: Paths.auth.forgotPassword,
        exact: true,
        component: ForgotPassword
    }
];
