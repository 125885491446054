import { AppRoute } from '@core/common/routes';
import { Privacy } from './Privacy';
import { Paths } from '@core/common/paths';

export const privacyRoute: AppRoute[] = [
    {
        path: Paths.legal.privacy,
        exact: true,
        component: Privacy
    }
];
