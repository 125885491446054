import React from 'react';
import classNames from 'classnames';
import style from './MenuToggle.scss';

interface ComponentProps {
    onToggle: () => void;
    active: boolean;
    solid?: boolean;
}

const MenuToggle: React.FC<ComponentProps> = ({ onToggle, active, solid }) => (
    <button
        type="button"
        className={classNames(style.menuToggle, active && style.active, solid && style.solid)}
        onClick={onToggle}>
        <div className={style.inner} />
    </button>
);

export { MenuToggle };
