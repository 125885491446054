import React from 'react';
import classNames from 'classnames';
import style from './FormField.scss';

interface Props {
    isRequired?: boolean;
    type: string;
    name: string;
    placeholder?: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string;
}

const FormField: React.FC<Props> = ({ isRequired, type, name, placeholder, value, onChange, error }) => {
    const [focus, setFocus] = React.useState(false);

    return (
        <div className={classNames(style.inputWrap, focus && style.focus, error && style.error)}>
            <label htmlFor={value}>
                {placeholder || ''}
                {isRequired && '*'}
            </label>
            <div className={style.field}>
                <input
                    type={type}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    placeholder={placeholder || ''}
                />
            </div>
            {error && <div className={style.error}>{error}</div>}
        </div>
    );
};

export { FormField };
