import { Dispatch } from 'redux';
import { AppApi } from '@core/api';
import { AppState } from '@core/common/reducer';
import { ChangePasswordTypes } from './Types';
import { ChangePasswordPayload, ChangePasswordResponse } from './Interface';

const ChangePassword = (data: ChangePasswordPayload) => {
    return async (dispatch: Dispatch, getState: () => AppState, { requestApi }: AppApi) => {
        try {
            dispatch({
                type: ChangePasswordTypes.CHANGE_PASSWORD_REQUEST
            });
            const res = await requestApi.post<ChangePasswordResponse>('/user/password', data);
            dispatch({
                type: ChangePasswordTypes.CHANGE_PASSWORD_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: ChangePasswordTypes.CHANGE_PASSWORD_FAILURE,
                payload: err.response.data
            });
        }
    };
};

export { ChangePassword };
