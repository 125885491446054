import React from 'react';
import { Link } from 'react-router-dom';
import style from './Privacy.scss';
import { config } from '@core/common/config';
import { Paths } from '@core/common/paths';
import { PageTemplate } from '@core/ui/templates';
import { Container } from '@core/ui/atoms/Container';

const options = {
    body: {},
    header: {
        include: true,
        theme: 'solid' as 'solid'
    },
    footer: {
        include: true
    },
    seo: {
        title: 'Privacy Policy - BarcodeBee',
        meta: [
            {
                name: 'description',
                content: 'Privacy Policy - BarcodeBee'
            }
        ]
    }
};

const Privacy: React.FC = () => {
    return (
        <PageTemplate options={options}>
            <div className={style.privacyPage}>
                <Container>
                    <div className={style.privacyPageInner}>
                        <h1>Privacy policy</h1>

                        <p>
                            Your privacy is important to us. We don’t ask for your personal information unless we truly
                            need it. We don’t share your personal information with third-parties, except where required
                            by law.
                        </p>

                        <p>
                            It is {config.siteName}&apos;s policy to respect your privacy regarding any information we
                            may collect from you across our website, <Link to={Paths.main.home}>{config.siteUrl}</Link>.
                        </p>

                        <h4>Personal Information</h4>

                        <p>
                            We may ask you for personal information, such as your name, email, address, contact details
                            and information about your business. We collect only the personal information relevant to
                            providing you with a service, and use your information only to ensure the fulfilment of this
                            service. You are free to refuse our request for your personal information, with the
                            understanding that we may be unable to provide you with some of your desired services
                            without this information.
                        </p>

                        <p>
                            We do not share your personal information with third-parties, except where required by law
                            or to protect our own rights. We will only retain personal information for as long as
                            necessary to provide you with a service.
                        </p>

                        <h4>Cookies</h4>

                        <p>
                            A cookie is a small piece of data that our website stores on your computer, and accesses
                            each time you visit so we can understand how you use our site and serve you content based on
                            preferences you have specified. If you do not wish to accept cookies from us, you should
                            instruct your browser to refuse cookies from{' '}
                            <Link to={Paths.main.home}>{config.siteUrl}</Link>.
                        </p>

                        <h4>Third-Party Services</h4>

                        <p>
                            We may employ third-party companies and individuals on our websites - for example, analytics
                            providers. These third parties have access to your personal information only to perform
                            specific tasks on our behalf, and are obligated not to disclose or use it for any other
                            purpose.
                        </p>

                        <h4>Security</h4>

                        <p>
                            We take security seriously, and do what we can within commercially acceptable means to
                            protect your personal information from loss or theft, as well as unauthorized access,
                            disclosure, copying, use or modification. That said, we advise that no method of electronic
                            transmission or storage is 100% secure, and cannot guarantee the absolute security of your
                            data.
                        </p>

                        <h4>Links to Other Sites</h4>

                        <p>
                            Our website may link to external sites that are not operated by us. Please be aware that we
                            have no control over the content and practices of these sites, and cannot assume
                            responsibility for their treatment of your personal information. This privacy policy only
                            covers our website and privacy practices.
                        </p>

                        <h4>Business Transfers</h4>

                        <p>
                            If we or our assets are acquired, or in the unlikely event that we go out of business or
                            enter bankruptcy, we would include user information among our assets transferred to or
                            acquired by a third party. You acknowledge that such transfers may occur, and that any
                            parties who acquire us may continue to use your personal information according to this
                            policy.
                        </p>

                        <h4>Changes to our Privacy Policy</h4>

                        <p>
                            At our discretion, we may change our privacy policy from time to time. Your continued use of
                            this site after any changes to this policy will be regarded as acceptance of our practices
                            around privacy and personal information.
                        </p>
                    </div>
                </Container>
            </div>
        </PageTemplate>
    );
};

export { Privacy };
