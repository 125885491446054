import { Dispatch } from 'redux';
import { AppApi } from '@core/api';
import { AppState } from '@core/common/reducer';
import { PayPalCreatePaymentTypes } from './Types';
import { PayPalCreatePaymentResponse, PayPalCreatePaymentPayload } from './Interface';

const PayPalCreatePayment = (data: PayPalCreatePaymentPayload) => {
    return async (dispatch: Dispatch, getState: () => AppState, { requestApi }: AppApi) => {
        try {
            dispatch({
                type: PayPalCreatePaymentTypes.CREATE_PAYPAL_PAYMENT_REQUEST
            });
            const res = await requestApi.post<PayPalCreatePaymentResponse>('/payments/paypal/create_payment', data);
            dispatch({
                type: PayPalCreatePaymentTypes.CREATE_PAYPAL_PAYMENT_SUCCESS,
                payload: res.data
            });

            // Return the orderID back to the component;
            return res.data.orderID;
        } catch (err) {
            dispatch({
                type: PayPalCreatePaymentTypes.CREATE_PAYPAL_PAYMENT_FAILURE,
                payload: err.response.data
            });

            // Return null back to the component
            return null;
        }
    };
};

export { PayPalCreatePayment };
