import { AppRoute } from '@core/common/routes';
import { HowItWorks } from './HowItWorks';
import { Paths } from '@core/common/paths';

export const howItWorksRoute: AppRoute[] = [
    {
        path: Paths.resources.howitworks,
        exact: true,
        component: HowItWorks
    }
];
