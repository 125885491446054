import React from 'react';
import { connect } from 'react-redux';
import * as Api from '@core/store/effects';
import { FormField } from '@core/ui/atoms/FormField';
import { Button } from '@core/ui/atoms/Button';
import style from './ChangePasswordForm.scss';
import { AllProps, StateProps, DispatchProps, FormData, FormErrors } from './ChangePasswordForm.interface';
import { createLoadingSelector } from '@core/store/selectors/request/loadingSelector';
import { createErrorMessageSelector } from '@core/store/selectors/request/errorsSelector';
import { ChangePasswordTypes } from '@core/store/effects/User';
import { AppState } from '@core/common/reducer';
import { GlobalError } from '@core/ui/atoms/GlobalError';
import { createSuccessSelector } from '@core/store/selectors/request/successSelector';

const ChangePasswordFormInternal: React.FC<AllProps> = ({
    requestChangePassword,
    isFetching,
    requestErrors,
    success
}) => {
    const [data, setData] = React.useState<FormData>({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [errors, setErrors] = React.useState<FormErrors>({});

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    const validate = (): FormErrors => {
        const formErrors: FormErrors = {};

        if (!data.oldPassword) {
            formErrors.oldPassword = 'Current password is required';
        }

        if (!data.newPassword) {
            formErrors.newPassword = 'New password is required';
        }

        if (data.newPassword !== data.confirmPassword) {
            formErrors.confirmPassword = 'Passwords do not match';
        }

        return formErrors;
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const formErrors = validate();
        setErrors(formErrors);
        if (Object.keys(formErrors).length === 0) {
            requestChangePassword(data);
        }
    };

    return (
        <div className={style.changePasswordForm}>
            <form onSubmit={onSubmit}>
                <FormField
                    type="password"
                    name="oldPassword"
                    placeholder="Current password"
                    value={data.oldPassword}
                    onChange={onChange}
                    error={errors.oldPassword}
                />
                <FormField
                    type="password"
                    name="newPassword"
                    placeholder="New password"
                    value={data.newPassword}
                    onChange={onChange}
                    error={errors.newPassword}
                />
                <FormField
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm new password"
                    value={data.confirmPassword}
                    onChange={onChange}
                    error={errors.confirmPassword}
                />
                <Button text="Update Password" loading={isFetching} type="submit" />
            </form>
            {success && <div className={style.success}>Success, your password has been updated.</div>}
            {requestErrors && (
                <div className={style.errors}>
                    <GlobalError errors={requestErrors} />
                </div>
            )}
        </div>
    );
};

const successSelector = createSuccessSelector([ChangePasswordTypes.BASE]);
const loadingSelector = createLoadingSelector([ChangePasswordTypes.BASE]);
const errorsSelector = createErrorMessageSelector([ChangePasswordTypes.BASE]);

const mapStateToProps = (state: AppState): StateProps => ({
    success: successSelector(state),
    isFetching: loadingSelector(state),
    requestErrors: errorsSelector(state)
});

const mapDispatchToProps: DispatchProps = {
    requestChangePassword: Api.User.ChangePassword
};

const ChangePasswordForm = connect(mapStateToProps, mapDispatchToProps)(ChangePasswordFormInternal);

export { ChangePasswordForm };
