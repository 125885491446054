import React from 'react';
import { connect } from 'react-redux';
import style from './CompleteStep.scss';
import image from '@core/assets/images/credit_card.svg';
import { CircularIcon } from '@core/ui/atoms/CircularIcon';
import { Button } from '@core/ui/atoms';
import { Paths } from '@core/common/paths';
import { UserModel } from '@core/interface/models/user';
import { AppState } from '@core/common/reducer';

interface StateProps {
    user?: UserModel;
}

interface ComponentProps {}

const CompleteStepInternal: React.FC<ComponentProps & StateProps> = ({ user }) => {
    if (!user) {
        return null;
    }

    return (
        <div className={style.component}>
            <div className={style.imageWrap}>
                <img src={image} width="200" alt="credit-card" />
                <div className={style.circle}>
                    <CircularIcon icon="check" />
                </div>
            </div>
            <h3>Order Success</h3>
            <p>
                {user.firstName}, your barcode order was successful! Thanks for choosing BarcodeBee, you can now
                continue to your dashboard where you can download your barcodes.
            </p>
            <Button enlarge link={Paths.account.root} text="Download my Barcodes" />
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    user: state.auth.user
});

const CompleteStep = connect(mapStateToProps)(CompleteStepInternal);

export { CompleteStep };
