import { AppRoute } from '@core/common/routes';
import { Pricing } from './Pricing';
import { Paths } from '@core/common/paths';

export const pricingRoute: AppRoute[] = [
    {
        path: Paths.main.pricing,
        exact: true,
        component: Pricing
    }
];
