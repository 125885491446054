import { AppRoute } from '@core/common/routes';
import { AccountCertificates } from './AccountCertificates';
import { Paths } from '@core/common/paths';

export const accountCertificatesRoute: AppRoute[] = [
    {
        path: Paths.account.certificates,
        exact: true,
        component: AccountCertificates
    }
];
