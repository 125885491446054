import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Api from '@core/store/effects';
import { Loading } from '@core/ui/atoms/Loading';
import { CircularIcon } from '@core/ui/atoms/CircularIcon';
import { Paths } from '@core/common/paths';
import style from './VerifyEmail.scss';
import { PageTemplate } from '@core/ui/templates';
import { AppState } from '@core/common/reducer';
import { createLoadingSelector } from '@core/store/selectors/request/loadingSelector';
import { createErrorMessageSelector } from '@core/store/selectors/request/errorsSelector';
import { ApiErrors } from '@core/interface/errors';
import { FormWrapper } from '@core/ui/molecules/global/FormWrapper';
import { Container } from '@core/ui/atoms/Container';

interface StateProps {
    isFetching: boolean;
    requestErrors: ApiErrors;
}

interface DispatchProps {
    requestVerifyConfirmationEmail: (data: Api.Auth.VerifyPasswordResetCodePayload) => void;
}

type AllProps = StateProps & DispatchProps;

const options = {
    body: {},
    header: {
        include: true,
        theme: 'solid' as 'solid'
    },
    footer: {
        include: true
    },
    seo: {
        title: 'Email Verification - BarcodeBee',
        meta: [
            {
                name: 'description',
                content: 'Email Verification - BarcodeBee'
            }
        ]
    }
};

const VerifyEmailInternal: React.FC<AllProps> = ({ requestVerifyConfirmationEmail, isFetching, requestErrors }) => {
    // Run email verification on mount
    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('code');
        if (token) {
            requestVerifyConfirmationEmail({ code: token });
        }
    }, []);

    // If errors object changes setError
    // TODO: Verify this works..
    const hasError = !!Object.keys(requestErrors).length;

    return (
        <PageTemplate options={options}>
            <Container>
                <FormWrapper>
                    <div className={style.component}>
                        {isFetching && <Loading color="PRIMARY" size={10} />}
                        {!isFetching && !hasError && (
                            <>
                                <CircularIcon icon="check" />
                                <h3>Success</h3>
                                <p>Thank-you. Your account has been confirmed.</p>
                                <Link to={Paths.account.root}>Go to my Account</Link>
                            </>
                        )}
                        {!isFetching && hasError && (
                            <>
                                <CircularIcon icon="exclamation-triangle" />
                                <h3>Invalid Token</h3>
                                <p>
                                    We could not confirm your account. Please request another verification link or
                                    contact support.
                                </p>
                                <Link to={Paths.main.home}>Return home</Link>
                            </>
                        )}
                    </div>
                </FormWrapper>
            </Container>
        </PageTemplate>
    );
};

const loadingSelector = createLoadingSelector([Api.User.VerifyConfirmationEmailTokenTypes.BASE]);
const errorsSelector = createErrorMessageSelector([Api.User.VerifyConfirmationEmailTokenTypes.BASE]);

const mapStateToProps = (state: AppState): StateProps => ({
    isFetching: loadingSelector(state),
    requestErrors: errorsSelector(state)
});

const mapDispatchToProps: DispatchProps = {
    requestVerifyConfirmationEmail: Api.User.VerifyConfirmationEmailToken
};

const enhance = connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps);

export const VerifyEmail = enhance(VerifyEmailInternal);
