import React from 'react';
import { connect } from 'react-redux';
import style from './PaymentStep.scss';
import { StripeForm } from '@core/ui/molecules/payments/StripeForm';
import { TextSeparator } from '@core/ui/atoms/TextSeparator';
import { PayPalButton } from '@core/ui/molecules/payments/PayPalButton';
import { Icon } from '@core/ui/atoms/Icon';
import { AppState } from '@core/common/reducer';
import { mapBarcodeQuantityToPrice } from '@core/utils/helpers/mapBarcodeQuantityToPrice';
import * as Api from '@core/store/effects';

interface ComponentProps {}

interface StateProps {
    quantity?: number;
}

interface DispatchProps {
    toggleAuth: (data: Api.Auth.ToggleAuthPayload) => void;
}

type AllProps = ComponentProps & StateProps & DispatchProps;

const PaymentStepInternal: React.FC<AllProps> = ({ quantity, toggleAuth }) => {
    if (!quantity) {
        return null;
    }

    const price = mapBarcodeQuantityToPrice(quantity);

    if (!price) {
        return null;
    }

    return (
        <div className={style.component}>
            <div className={style.top}>
                <h6>Step 2</h6>
                <h3>Review & pay</h3>
                <p>
                    You have chosen to purchase {quantity} {quantity === 1 ? 'Barcode' : 'Barcodes'} for a total of $
                    {price / 100}. Complete your order below to recieve your barcodes instantly or{' '}
                    <span onClick={() => toggleAuth({ step: 1 })} className={style.change}>
                        change your package
                    </span>
                    .
                </p>
            </div>
            <StripeForm />
            <TextSeparator text="or pay with" />
            <PayPalButton />
            <div className={style.message}>
                <Icon name="lock-alt" size="16" />
                <span>All payments are processed securely using world-class payment processors.</span>
            </div>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    return {
        quantity: state.checkout.quantityChosen
    };
};

const mapDispatchToProps: DispatchProps = {
    toggleAuth: Api.Auth.ToggleAuth
};

const PaymentStep = connect(mapStateToProps, mapDispatchToProps)(PaymentStepInternal);

export { PaymentStep };
