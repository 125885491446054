import React from 'react';
import { connect } from 'react-redux';
import * as Api from '@core/store/effects';
import { FormField } from '@core/ui/atoms/FormField';
import { Button } from '@core/ui/atoms/Button';
import style from './ChangeEmailForm.scss';
import { AllProps, StateProps, DispatchProps, FormData, FormErrors } from './ChangeEmailForm.interface';
import { AppState } from '@core/common/reducer';
import { createLoadingSelector } from '@core/store/selectors/request/loadingSelector';
import { createErrorMessageSelector } from '@core/store/selectors/request/errorsSelector';
import { ChangeEmailTypes } from '@core/store/effects/User';
import { ConfirmPasswordModal } from '@core/ui/molecules/global/ConfirmPasswordModal';
import { isEmail } from '@core/utils/validate';
import { GlobalError } from '@core/ui/atoms/GlobalError';
import { createSuccessSelector } from '@core/store/selectors/request/successSelector';

const ChangeEmailFormInternal: React.FC<AllProps> = ({ changeEmail, auth, isFetching, requestErrors, success }) => {
    const { user } = auth;
    const [data, setData] = React.useState<FormData>({
        email: user ? user.email : ''
    });
    const [errors, setErrors] = React.useState<FormErrors>({});

    // If the user has updated their email, must confirm password
    const [confirmPassword, setConfirmPassword] = React.useState(false);

    // Update request errors when they come through
    React.useEffect(() => {
        setErrors(requestErrors);
    }, [requestErrors]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    const validate = (): FormErrors => {
        const formErrors: FormErrors = {};

        if (!isEmail(data.email)) {
            formErrors.email = 'Email address is invalid';
        }

        return formErrors;
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const formErrors = validate();
        setErrors(formErrors);
        if (Object.keys(formErrors).length === 0 && user) {
            setConfirmPassword(true);
        }
    };

    const onConfirmPassword = async (password: string) => {
        // Send password with payload
        changeEmail({ email: data.email, password });

        // Close the modal
        setConfirmPassword(false);
    };

    return (
        <div className={style.editDetailsForm}>
            <form onSubmit={onSubmit}>
                <FormField
                    type="email"
                    name="email"
                    placeholder="Email address"
                    value={data.email}
                    onChange={onChange}
                    error={errors.email}
                />
                <Button text="Save" loading={isFetching} type="submit" />
            </form>
            {errors && (
                <div className={style.errors}>
                    <GlobalError errors={requestErrors} />
                </div>
            )}
            {success && <div className={style.success}>Success, your email address has been updated.</div>}
            {confirmPassword && <ConfirmPasswordModal onSubmit={onConfirmPassword} />}
        </div>
    );
};

const successSelector = createSuccessSelector([ChangeEmailTypes.BASE]);
const loadingSelector = createLoadingSelector([ChangeEmailTypes.BASE]);
const errorsSelector = createErrorMessageSelector([ChangeEmailTypes.BASE]);

const mapStateToProps = (state: AppState): StateProps => ({
    auth: state.auth,
    success: successSelector(state),
    isFetching: loadingSelector(state),
    requestErrors: errorsSelector(state)
});

const mapDispatchToProps: DispatchProps = {
    changeEmail: Api.User.ChangeEmail
};

const ChangeEmailForm = connect(mapStateToProps, mapDispatchToProps)(ChangeEmailFormInternal);

export { ChangeEmailForm };
