import { Dispatch } from 'redux';
import { AppApi } from '@core/api';
import { AppState } from '@core/common/reducer';
import { AnalyticsInitTypes } from './Types';
import { AnalyticsInitPayload, AnalyticsInitResponse } from './Interface';

const RequestAnalyticsInit = (data: AnalyticsInitPayload): any => {
    return async (dispatch: Dispatch, getState: () => AppState, { requestApi }: AppApi) => {
        try {
            dispatch({
                type: AnalyticsInitTypes.ANALYTICS_INIT_REQUEST
            });
            const res = await requestApi.post<AnalyticsInitResponse>('/analytics', data);
            dispatch({
                type: AnalyticsInitTypes.ANALYTICS_INIT_SUCCESS,
                payload: res.data
            });
            return true;
        } catch (err) {
            dispatch({
                type: AnalyticsInitTypes.ANALYTICS_INIT_FAILURE,
                payload: err.response.data
            });
            return false;
        }
    };
};

export { RequestAnalyticsInit };
