import React from 'react';
import classes from 'classnames';
import style from './HowItWorks.scss';
import { PageTemplate } from '@core/ui/templates';
import { Container } from '@core/ui/atoms/Container';

import image1 from '@core/assets/images/ud_content.svg';
import image2 from '@core/assets/images/ud_list.svg';
import image3 from '@core/assets/images/ud_shopping.svg';
import { Link } from 'react-router-dom';
import { Paths } from '@core/common/paths';
import { CallToAction } from '@core/ui/molecules/global/CallToAction';

const options = {
    body: {},
    header: {
        include: true,
        theme: 'solid' as 'solid'
    },
    footer: {
        include: true
    },
    seo: {
        title: 'How It Works - BarcodeBee',
        meta: [
            {
                name: 'description',
                content: 'How It Works - BarcodeBee'
            }
        ]
    }
};

const HowItWorksInternal = () => (
    <PageTemplate options={options}>
        <div className={style.component}>
            <Container>
                <div className={style.head}>
                    <h6>3 Simple Steps</h6>
                    <h1>How it works</h1>
                    <p>
                        Getting barcodes for your products, onto your products and into stores is easy. Follow the
                        simple 3 step process below to find out how.
                    </p>
                </div>
                <div className={style.row}>
                    <div className={style.col}>
                        <h6>Step 1</h6>
                        <h3>Get a barcode for each different product variation</h3>
                        <p>
                            The first step is to decide how many barcodes you'll need. You'll need 1 for each product
                            and each variation of that product, more information found{' '}
                            <Link to={Paths.resources.whattoknow}>here</Link>. Once you know how many you'll need you
                            can head over to our <Link to={Paths.main.pricing}>order page</Link> and checkout with your
                            barcode package.
                        </p>
                    </div>
                    <div className={style.col}>
                        <img src={image2} width="400" />
                    </div>
                </div>
                <div className={classes(style.row, style.middle)}>
                    <div className={style.col}>
                        <img src={image1} width="400" />
                    </div>
                    <div className={style.col}>
                        <h6>Step 2</h6>
                        <h3>Apply the barcode onto your product or its packaging</h3>
                        <p>
                            Once you've downloaded your barcode package from inside your BarcodeBee account, you'll need
                            to get your barcodes onto your products. You can do this by adding your barcode onto your
                            products packaging, either directly applied into the packagings design or by printing your
                            barcodes onto stickers and placing them on your products.
                        </p>
                    </div>
                </div>
                <div className={classes(style.row, style.last)}>
                    <div className={style.col}>
                        <h6>Step 3</h6>
                        <h3>Bring your product into stores and start selling</h3>
                        <p>
                            Now you're ready to get your products into stores! You can contact the retailers that will
                            be selling your products and let them know. Once they have recieved your products with your
                            barcodes on them, they will have a product onboarding process where they scan your barcodes
                            through their system and associate them with your products.
                        </p>
                    </div>
                    <div className={style.col}>
                        <img src={image3} width="375" />
                    </div>
                </div>
            </Container>
            <CallToAction />
        </div>
    </PageTemplate>
);

export const HowItWorks = HowItWorksInternal;
