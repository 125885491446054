import React from 'react';
import classes from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Api from '@core/store/effects';
import { FormField } from '@core/ui/atoms/FormField';
import { Button } from '@core/ui/atoms/Button';
import { GlobalError } from '@core/ui/atoms/GlobalError';
import { AllProps, DispatchProps, FormErrors, FormData, StateProps } from './ContactForm.interface';
import style from './ContactForm.scss';
import { createLoadingSelector } from '@core/store/selectors/request/loadingSelector';
import { AppState } from '@core/common/reducer';
import { createErrorMessageSelector } from '@core/store/selectors/request/errorsSelector';
import { isEmail } from '@core/utils/validate';
import { createSuccessSelector } from '@core/store/selectors/request/successSelector';
import { CircularIcon } from '@core/ui/atoms/CircularIcon';

const ContactFormInternal: React.FC<AllProps> = ({ requestContact, isFetching, requestErrors, requestSuccess }) => {
    const [data, setData] = React.useState<FormData>({
        name: '',
        company: '',
        email: '',
        phone: '',
        orderId: '',
        message: ''
    });
    const [errors, setErrors] = React.useState<FormErrors>({});

    React.useEffect(() => {
        if (requestSuccess) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }, [requestSuccess]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    const validate = (): FormErrors => {
        const formErrors: FormErrors = {};

        if (!data.name) {
            formErrors.name = 'Name is required';
        }

        if (!data.company) {
            formErrors.company = 'Company is required';
        }

        if (!isEmail(data.email)) {
            formErrors.email = 'Email address is invalid';
        }

        if (!data.message) {
            formErrors.message = 'Message is required';
        }

        return formErrors;
    };

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const formErrors = validate();
        setErrors(formErrors);
        if (Object.keys(formErrors).length === 0) {
            requestContact(data);
        }
    };

    if (requestSuccess) {
        return (
            <div className={classes(style.wrapper, style.success)}>
                <CircularIcon icon="check" />
                <h6>Message Success</h6>
                <h1>Message sent successfully</h1>
                <p>
                    Thank-you for getting in touch with us. Your message has been sent successfully, we'll be in contact
                    with you via email shortly.
                </p>
            </div>
        );
    }

    return (
        <div className={style.wrapper}>
            <h6>Support</h6>
            <h1>Contact us</h1>
            <p>
                If you have any queries about a new or existing order, or simply just need to get in touch, please fill
                out the form below and we will get back to you shortly. Alternatively you can email us directly at{' '}
                <a href="mailto:support@barcodebee.com">support@barcodebee.com</a>.
            </p>
            <form className={style.component} onSubmit={onSubmit}>
                {requestErrors && (
                    <div className={style.globalError}>
                        <GlobalError errors={requestErrors} />
                    </div>
                )}
                <div className={style.row}>
                    <div className={style.col}>
                        <FormField
                            isRequired
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={data.name}
                            onChange={onChange}
                            error={errors.name}
                        />
                    </div>
                    <div className={style.col}>
                        <FormField
                            isRequired
                            type="text"
                            name="company"
                            placeholder="Company"
                            value={data.company}
                            onChange={onChange}
                            error={errors.company}
                        />
                    </div>
                </div>
                <div className={style.row}>
                    <div className={style.col}>
                        <FormField
                            isRequired
                            type="email"
                            name="email"
                            placeholder="Email address"
                            value={data.email}
                            onChange={onChange}
                            error={errors.email}
                        />
                    </div>
                    <div className={style.col}>
                        <FormField
                            type="text"
                            name="phone"
                            placeholder="Phone number"
                            value={data.phone}
                            onChange={onChange}
                            error={errors.phone}
                        />
                    </div>
                </div>
                <FormField
                    type="text"
                    name="orderId"
                    placeholder="Existing Order ID (optional)"
                    value={data.orderId}
                    onChange={onChange}
                    error={errors.orderId}
                />
                <div className={style.message}>
                    <label>Message</label>
                    <textarea
                        className={classes(!!errors.message && style.hasError)}
                        name="message"
                        onChange={e => setData({ ...data, message: e.target.value })}
                        value={data.message}
                    />
                    {!!errors.message && <div className={style.error}>{errors.message}</div>}
                </div>

                <Button text="Send message" loading={isFetching} type="submit" extend />
            </form>
        </div>
    );
};

const loadingSelector = createLoadingSelector([Api.User.ContactTypes.BASE]);
const errorsSelector = createErrorMessageSelector([Api.User.ContactTypes.BASE]);
const successSelector = createSuccessSelector([Api.User.ContactTypes.BASE]);

const mapStateToProps = (state: AppState): StateProps => ({
    isFetching: loadingSelector(state),
    requestErrors: errorsSelector(state),
    requestSuccess: successSelector(state)
});

const mapDispatchToProps: DispatchProps = {
    requestContact: Api.User.Contact
};

const ContactForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactFormInternal));

export { ContactForm };
