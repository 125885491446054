import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import * as Api from '@core/store/effects';
import { AppState } from '@core/common/reducer';
import { createLoadingSelector } from '@core/store/selectors/request/loadingSelector';
import { LogoutTypes } from '@core/store/effects/Auth';

interface ComponentProps extends RouteComponentProps {
    className?: string;
    redirectUrl?: string;
}

interface StateProps {
    isFetching: boolean;
}

interface DispatchProps {
    requestLogout: () => void;
}

type AllProps = ComponentProps & StateProps & DispatchProps;

const LogoutButtonInternal: React.FC<AllProps> = ({
    className,
    redirectUrl,
    isFetching,
    requestLogout,
    history,
    children
}) => {
    const [redirect, setRedirect] = React.useState<boolean | undefined>(undefined);

    React.useEffect(() => {
        // If we are on a protected route switching isAuthenticated to false will redirect the user.
        // However this will run as a fallback. If we make the logout request `setRedirect` to true, & when
        // isFetching gets set to false we use this as a trigger to know when to redirect the user.
        if (redirectUrl) {
            if (isFetching === true) {
                setRedirect(true);
            } else if (isFetching === false && redirect) {
                history.push(redirectUrl);
            }
        }
    }, [isFetching]);

    return (
        <button type="button" onClick={() => requestLogout()} className={className}>
            {children}
        </button>
    );
};

const loadingSelector = createLoadingSelector([LogoutTypes.BASE]);

const mapStateToProps = (state: AppState): StateProps => ({
    isFetching: loadingSelector(state)
});

const mapDispatchToProps: DispatchProps = {
    requestLogout: Api.Auth.RequestLogout
};

const LogoutButton = withRouter(connect(mapStateToProps, mapDispatchToProps)(LogoutButtonInternal));

export { LogoutButton };
