import React from 'react';
import style from './Modal.scss';
import { Icon } from '../Icon';
import { CSSTransition } from 'react-transition-group';

interface Props {
    open: boolean;
    onClose?: () => void;
}

const transition = {
    enter: style.enter,
    enterActive: style.enterActive,
    exit: style.exit,
    exitActive: style.exitActive
};

const Modal: React.FC<Props> = ({ open, onClose, children }) => {
    return (
        <CSSTransition in={open} timeout={700} unmountOnExit classNames={transition}>
            <div className={style.modal}>
                <div className={style.overlay} onClick={onClose} />
                <div className={style.body}>
                    {onClose && (
                        <div
                            aria-label="Close"
                            role="button"
                            tabIndex={-1}
                            onClick={onClose}
                            onKeyDown={onClose}
                            className={style.close}>
                            <Icon name="times" size="20px" color="#484848" />
                        </div>
                    )}
                    <div className={style.bodyInner}>{children}</div>
                </div>
            </div>
        </CSSTransition>
    );
};

export { Modal };
