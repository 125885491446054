import { AuthReducerState } from '@core/store/reducers/AuthReducer';
import { FetchCurrentUserResponse } from '@core/store/effects/User';

export const fetchCurrentUserFailure = (
    state: AuthReducerState,
    payload: FetchCurrentUserResponse
): AuthReducerState => {
    const { success } = payload;
    return {
        isAuthenticated: success
    };
};
