import * as React from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Header } from '@core/ui/molecules/global/Header';
import { Footer } from '@core/ui/molecules/global/Footer';
import styles from './PageTemplate.scss';

interface MetaItem {
    name: string;
    content: string;
}

interface Props {
    children: React.ReactNode;
    options: {
        body: {
            /**
             * The app background color for this page
             */
            background?: string;
        };
        header: {
            /**
             * Includes the header
             */
            include: boolean;
            /**
             * Changes the headers theme
             */
            theme?: 'solid';
            /**
             * Will remove box-shadow/border properties
             */
            clean?: boolean;
            /**
             * Changes the headers background color
             */
            background?: string;
        };
        footer: {
            /**
             * Includes the footer
             */
            include: boolean;
        };
        seo: {
            title: string;
            meta: MetaItem[];
        };
    };
}

const PageTemplate: React.FC<Props> = ({ children, options }) => {
    const { pathname } = useLocation();

    // Smooth scroll to top on route change
    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [pathname]);

    return (
        <div className={classNames(styles.pageTemplate && styles.greyBg)}>
            <Helmet title={options.seo.title} meta={options.seo.meta} />
            {options.header.include && <Header theme={options.header.theme} isAbsolute={options.header.clean} />}
            {children}
            {options.footer.include && <Footer />}
        </div>
    );
};

export { PageTemplate };
