import { AppRoute } from '@core/common/routes';
import { Signup } from './Signup';
import { Paths } from '@core/common/paths';

export const signupRoute: AppRoute[] = [
    {
        path: Paths.auth.signup,
        exact: true,
        component: Signup
    }
];
