import * as React from 'react';
import { RouteConfig, RouteConfigComponentProps } from 'react-router-config';
import { unnest } from 'ramda';

import { PreloadAction } from './preload';
import { verifyEmailRoute } from '@core/ui/pages/VerifyEmail';
import { accountRoute } from '@core/ui/pages/Account';
import { accountPersonalRoute } from '@core/ui/pages/AccountPersonal';
import { accountCertificatesRoute } from '@core/ui/pages/AccountCertificates';
import { accountPaymentsRoute } from '@core/ui/pages/AccountPayments';
import { forgotPasswordRoute } from '@core/ui/pages/ForgotPassword';
import { homeRoute } from '@core/ui/pages/Home';
import { loginRoute } from '@core/ui/pages/Login';
import { resetPasswordRoute } from '@core/ui/pages/ResetPassword';
import { pricingRoute } from '@core/ui/pages/Pricing';
import { signupRoute } from '@core/ui/pages/Signup';
import { notFoundRoute } from '@core/ui/pages/NotFound';
import { termsRoute } from '@core/ui/pages/Terms';
import { privacyRoute } from '@core/ui/pages/Privacy';
import { whatToKnowRoute } from '@core/ui/pages/WhatToKnow';
import { howItWorksRoute } from '@core/ui/pages/HowItWorks';
import { aboutRoute } from '@core/ui/pages/About';
import { contactRoute } from '@core/ui/pages/Contact';
import { accountCertificateDownloadRoute } from '@core/ui/pages/AccountCertificateDownload';

export interface RouteComponentProps<T = {}> extends RouteConfigComponentProps<T> {
    routes?: AppRoute[];
}

export interface AppRoute extends RouteConfig {
    path: string;
    routes?: AppRoute[];
    preloadActions?: PreloadAction | PreloadAction[];
    component: React.ComponentType<RouteComponentProps> | React.ComponentType;
}

export const appRoutes = unnest([
    verifyEmailRoute,
    accountRoute,
    accountPersonalRoute,
    accountCertificatesRoute,
    accountCertificateDownloadRoute,
    accountPaymentsRoute,
    forgotPasswordRoute,
    homeRoute,
    loginRoute,
    pricingRoute,
    resetPasswordRoute,
    signupRoute,
    termsRoute,
    privacyRoute,
    whatToKnowRoute,
    howItWorksRoute,
    aboutRoute,
    contactRoute,
    notFoundRoute
]);
