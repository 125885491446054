import { AppRoute } from '@core/common/routes';
import { Account } from './Account';
import { Paths } from '@core/common/paths';

export const accountRoute: AppRoute[] = [
    {
        path: Paths.account.root,
        exact: true,
        component: Account
    }
];
