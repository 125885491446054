import { AuthReducerState } from '@core/store/reducers/AuthReducer';
import { LoginResponse } from '@core/store/effects/Auth';

export const loginSuccess = (state: AuthReducerState, payload: LoginResponse): AuthReducerState => {
    const { success, user } = payload;
    return {
        ...state,
        isAuthenticated: success,
        user
    };
};
