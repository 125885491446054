import React from 'react';
import classes from 'classnames';
import style from './AccountHead.scss';
import { Link } from 'react-router-dom';
import { Paths } from '@core/common/paths';
import { LogoutButton } from '@core/ui/molecules/global/LogoutButton';

interface ComponentProps {
    title: string;
    active: string;
}

const AccountHead: React.FC<ComponentProps> = ({ title, active }) => {
    return (
        <div className={style.head}>
            <h1>{title}</h1>
            <div className={style.items}>
                <Link
                    to={Paths.account.root}
                    className={classes(style.link, active === Paths.account.root && style.active)}>
                    My Barcodes
                </Link>
                <Link
                    to={Paths.account.certificates}
                    className={classes(style.link, active === Paths.account.certificates && style.active)}>
                    Certificates
                </Link>
                <Link
                    to={Paths.account.payments}
                    className={classes(style.link, active === Paths.account.payments && style.active)}>
                    Payments
                </Link>
                <Link
                    to={Paths.account.personal}
                    className={classes(style.link, active === Paths.account.personal && style.active)}>
                    Account Info
                </Link>
                <LogoutButton className={style.link}>
                    <span>Log Out</span>
                </LogoutButton>
            </div>
        </div>
    );
};

export { AccountHead };
