import React from 'react';
import style from './Loading.scss';

interface Props {
    align?: 'LEFT' | 'CENTER' | 'RIGHT';
    color?: 'PRIMARY' | 'SECONDARY';
    size?: number;
    theme?: 'v2';
}

const Loading: React.FC<Props> = ({ color, size, align, theme }) => {
    let classes = style.loading;

    // Primary color
    if (color && color === 'PRIMARY') {
        classes += ` ${style.primary}`;
    }

    // Secondary color
    if (color && color === 'SECONDARY') {
        classes += ` ${style.secondary}`;
    }

    // Align left
    if (align && align === 'LEFT') {
        classes += ` ${style.alignLeft}`;
    }

    // Align right
    if (align && align === 'RIGHT') {
        classes += ` ${style.alignRight}`;
    }

    const sz = size ? size : 15;

    if (theme === 'v2') {
        return (
            <div className={style.v2Loader} style={{ height: `${sz}px`, width: `${sz}px` }}>
                <svg viewBox="22 22 44 44">
                    <circle cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6" />
                </svg>
            </div>
        );
    }

    return (
        <div className={classes}>
            <span style={{ height: `${sz}px`, width: `${sz}px`, marginRight: `${(sz / 10) * 7}` }} />
            <span style={{ height: `${sz}px`, width: `${sz}px`, marginRight: `${(sz / 10) * 7}` }} />
            <span style={{ height: `${sz}px`, width: `${sz}px`, marginRight: `${(sz / 10) * 7}` }} />
        </div>
    );
};

export { Loading };
