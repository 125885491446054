import React from 'react';
import image from '@core/assets/images/FontAwesomeLineIcons.svg';
import style from './Icon.scss';
import { Colors } from '@core/interface/styles/colors';

const colors = require('@core/assets/styles/colors.scss') as Colors;

interface Props {
    name: string;
    size?: string;
    color?: string;
}

const Icon: React.FC<Props> = ({ name, size, color }) => (
    <svg className={style.icon} width={size || '20px'} height={size || '20px'} fill={color || colors.textBase}>
        <use href={`${image}#${name}`} />
    </svg>
);

export { Icon };
