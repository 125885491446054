import React from 'react';
import * as Api from '@core/store/effects';
import { connect } from 'react-redux';
import classes from 'classnames';
import { PageTemplate } from '@core/ui/templates';
import style from './Pricing.scss';
import { AppState } from '@core/common/reducer';
import { Container } from '@core/ui/atoms/Container';
import { PaymentStep } from '@core/ui/molecules/pages/pricing/PaymentStep';
import { Icon } from '@core/ui/atoms/Icon';
import { PackageStep } from '@core/ui/molecules/pages/pricing/PackageStep';
import { CompleteStep } from '@core/ui/molecules/pages/pricing/CompleteStep';

const options = {
    body: {},
    header: {
        include: true,
        theme: 'solid' as 'solid'
    },
    footer: {
        include: true
    },
    seo: {
        title: 'Pricing - BarcodeBee',
        meta: [
            {
                name: 'description',
                content: 'Pricing - BarcodeBee'
            }
        ]
    }
};

interface StateProps {
    isAuthenticated: boolean;
    step: number;
}

interface DispatchProps {
    toggleAuth: (data: Api.Auth.ToggleAuthPayload) => void;
}

interface ComponentProps {}

type AllProps = ComponentProps & DispatchProps & StateProps;

const PricingInternal: React.FC<AllProps> = ({ isAuthenticated, toggleAuth, step }) => {
    React.useEffect(() => {
        return () => toggleAuth({ step: 1 });
    }, []);

    return (
        <PageTemplate options={options}>
            <div className={style.pricingPage}>
                <Container>
                    <div className={style.info}>
                        <div className={style.stepper}>
                            <div className={style.border} />
                            <div className={style.steps}>
                                <div className={classes(style.step, step === 1 && style.active)}>
                                    <div className={style.round}>
                                        {step > 0 ? <Icon name="check" size="12px" color="#fff" /> : '1'}
                                    </div>
                                    <div className={style.text}>Package</div>
                                </div>
                                <div className={classes(style.step, step === 2 && style.active)}>
                                    <div className={style.round}>
                                        {step > 1 ? <Icon name="check" size="12px" color="#fff" /> : '2'}
                                    </div>
                                    <div className={style.text}>Payment</div>
                                </div>
                                <div className={classes(style.step, step === 3 && style.active)}>
                                    <div className={style.round}>
                                        {step > 2 ? <Icon name="check" size="12px" color="#fff" /> : '3'}
                                    </div>
                                    <div className={style.text}>Complete</div>
                                </div>
                            </div>
                        </div>
                        {step === 1 && <PackageStep />}
                        {step === 2 && <PaymentStep />}
                        {step === 3 && <CompleteStep />}
                    </div>
                </Container>
            </div>
        </PageTemplate>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    isAuthenticated: state.auth.isAuthenticated,
    step: state.checkout.currentStep
});

const mapDispatchToProps: DispatchProps = {
    toggleAuth: Api.Auth.ToggleAuth
};

export const Pricing = connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(PricingInternal);
