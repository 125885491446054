import { Paths } from '@core/common/paths';
import { Button } from '@core/ui/atoms';
import React from 'react';
import style from './AccountEmpty.scss';

interface ComponentProps {
    text: string;
}

export const AccountEmpty: React.FC<ComponentProps> = ({ text }) => {
    return (
        <div className={style.empty}>
            <p>{text}</p>
            <div className={style.action}>
                <Button link={Paths.main.pricing} text="Get Barcodes" />
            </div>
        </div>
    );
};
