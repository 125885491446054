import React from 'react';
import * as Api from '@core/store/effects';
import { PrivateRoute } from '@core/ui/molecules/hocs/PrivateRoute';
import style from './AccountPayments.scss';
import { PageTemplate } from '@core/ui/templates';
import { PaymentsReducerState } from '@core/store/reducers/PaymentsReducer';
import { Table, TableHead, TableItem } from '@core/ui/atoms/Table';
import { getDateFromTimestamp } from '@core/utils/date';
import { createLoadingSelector } from '@core/store/selectors/request/loadingSelector';
import { FetchUserPaymentsTypes } from '@core/store/effects/Payments';
import { AppState } from '@core/common/reducer';
import { connect } from 'react-redux';
import { AccountHead } from '@core/ui/molecules/pages/dash/AccountHead';
import { Container } from '@core/ui/atoms/Container';
import { Loading } from '@core/ui/atoms/Loading';
import { Paths } from '@core/common/paths';
import { UserModel } from '@core/interface/models/user';
import { AccountEmpty } from '@core/ui/molecules/pages/dash/AccountEmpty';

const options = {
    body: {},
    header: {
        include: true,
        theme: 'solid' as 'solid'
    },
    footer: {
        include: true
    },
    seo: {
        title: 'Payments - BarcodeBee',
        meta: [
            {
                name: 'description',
                content: 'Payments - BarcodeBee'
            }
        ]
    }
};

interface StateProps {
    payments: PaymentsReducerState;
    isFetching: boolean;
    user?: UserModel;
}

interface DispatchProps {
    fetchUserPayments: () => void;
}

type AllProps = StateProps & DispatchProps;

const AccountPaymentsInternal: React.FC<AllProps> = ({ fetchUserPayments, payments, user, isFetching }) => {
    React.useEffect(() => {
        if (typeof payments.list === 'undefined') {
            fetchUserPayments();
        }
    }, []);

    return (
        <PageTemplate options={options}>
            <div className={style.component}>
                <Container>
                    {!user || isFetching ? (
                        <Loading color="PRIMARY" size={10} />
                    ) : (
                        <>
                            <AccountHead title="Payment History" active={Paths.account.payments} />
                            {payments.list && payments.list.length ? (
                                <Table>
                                    <TableHead>
                                        <span>Description</span>
                                        <span>Date</span>
                                        <span>Amount</span>
                                        <span className={style.last}>Payment ID</span>
                                    </TableHead>
                                    {payments.list.map(payment => (
                                        <TableItem key={payment.paymentId}>
                                            <span>{payment.description}</span>
                                            <span>{getDateFromTimestamp(payment.created)}</span>
                                            <span>${(payment.amountInCents / 100).toFixed(2)}</span>
                                            <span className={style.last}>{payment.paymentId.replace(/ch_/g, '')}</span>
                                        </TableItem>
                                    ))}
                                </Table>
                            ) : (
                                <AccountEmpty text="You have not yet made any payments" />
                            )}
                        </>
                    )}
                </Container>
            </div>
        </PageTemplate>
    );
};

const loadingSelector = createLoadingSelector([FetchUserPaymentsTypes.BASE]);

const mapStateToProps = (state: AppState): StateProps => ({
    payments: state.payments,
    isFetching: loadingSelector(state),
    user: state.auth.user
});

const mapDispatchToProps: DispatchProps = {
    fetchUserPayments: Api.Payments.FetchUserPayments
};

const AccountPayments = PrivateRoute(connect(mapStateToProps, mapDispatchToProps)(AccountPaymentsInternal));

export { AccountPayments };
