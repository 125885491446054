import React from 'react';
import style from './WhatToKnow.scss';
import { PageTemplate } from '@core/ui/templates';
import { Container } from '@core/ui/atoms/Container';
import { AccordionItem } from '@core/ui/atoms/Accordion';

const options = {
    body: {},
    header: {
        include: true,
        theme: 'solid' as 'solid'
    },
    footer: {
        include: true
    },
    seo: {
        title: 'What To Know - BarcodeBee',
        meta: [
            {
                name: 'description',
                content: 'What To Know - BarcodeBee'
            }
        ]
    }
};

const WhatToKnowInternal = () => {
    const [active, setActive] = React.useState('');

    const onToggle = (id: string) => {
        setActive(active === id ? '' : id);
    };

    return (
        <PageTemplate options={options}>
            <div className={style.component}>
                <Container>
                    <h6>The FAQ</h6>
                    <h1>What to know</h1>
                    <AccordionItem
                        open={active}
                        id="ai-2"
                        title="How do I get my company & product information into my barcodes?"
                        onToggle={onToggle}>
                        <p>
                            Barcodes themselves do not contain any information within them. A barcode scanner will read
                            the barcode lines to determine which barcode number it is.
                        </p>
                        <p>
                            Every retailer will associate your products with your barcode numbers within their own
                            system prior to selling your products. They will inform you what information they want from
                            you in relation to your product & company and that information will then be associated with
                            your barcodes inside their own internal systems.
                        </p>
                    </AccordionItem>
                    <AccordionItem
                        open={active}
                        id="ai-3"
                        title="How many barcodes do I need to purchase?"
                        onToggle={onToggle}>
                        <p>
                            This depends on how many products you have, and how many different variations of those
                            products there are. Each "different" product in your inventory will require its own barcode
                            to uniquely identify itself in the retailers system when being scanned.
                        </p>

                        <p>
                            If you sell jackets in 5 different sizes and in 5 different colors, you will need 25
                            barcodes, 1 for each size and color. In another case you may have 100 jackets that are of
                            size medium and are in the color blue, you will only need 1 barcode for all 100 jackets as
                            they are of the same variation.
                        </p>
                    </AccordionItem>
                    <AccordionItem
                        open={active}
                        id="ai-6"
                        title="After purchasing a barcode package, how quickly will I recieve my order?"
                        onToggle={onToggle}>
                        <p>
                            After placing an order with BarcodeBee your barcode package will be ready to download from
                            within your account almost instantly.
                        </p>
                    </AccordionItem>
                    <AccordionItem open={active} id="ai-44" title="What is the UCC and who is GS1?" onToggle={onToggle}>
                        <p>
                            The UCC (Uniform Code Council) is a company where every single barcode number has originated
                            from. After a civil lawsuit was settled their name was changed to GS1 in 2005.
                        </p>
                        <p>
                            GS1 is a non-for-profit company which has been issuing barcode numbers longer than any other
                            barcode retailer.
                        </p>
                    </AccordionItem>
                    <AccordionItem
                        open={active}
                        id="ai-4"
                        title="Is GS1 in charge of all barcode numbers & are they a government agency?"
                        onToggle={onToggle}>
                        <p>
                            No, GS1 is not in charge of all barcode numbers. There is also no government agency in
                            charge of barcodes, or issuing barcodes. GS1 is a company just like BarcodeBee. The
                            difference is that they are a non-for-profit organisation, and are globally recognized.
                        </p>
                        <p>
                            Every barcode that we sell has originated from the UCC (now known as GS1). It's also worth
                            noting that any barcode purchased from BarcodeBee will not show up inside GS1's GEPIR
                            database.
                        </p>
                    </AccordionItem>
                    <AccordionItem
                        open={active}
                        id="ai-1"
                        title="Are the barcodes you supply new or used?"
                        onToggle={onToggle}>
                        <p>
                            Every single barcode we sell has originated from the UCC (Uniform Code Council) prior to
                            August 28, 2002. The UCC is now known as GS1.
                        </p>
                        <p>
                            Back in the 1990's barcodes were sold by prefix, meaning that each prefix would contain
                            thousands of barcode numbers. Due to each prefix having such a large quantity of numbers,
                            it's safe to assume that most owners would only use a few of the barcode numbers. If any
                            numbers were previously used, it was many years ago which means it is highly unlikely to be
                            inside anyones database. This means that the barcode number would be just like a brand new
                            number.
                        </p>
                        <p>
                            Even if you have purchased barcodes through GS1 you are not guaranteed to get a new barcode
                            number. This is because GS1 licences you use of those barcodes, and so if you do not pay
                            their renewal fees they will take the barcode away from you and re-licence it to someone
                            else.
                        </p>
                    </AccordionItem>
                    <AccordionItem
                        open={active}
                        id="ai-5"
                        title="Will my barcodes work in any country or store?"
                        onToggle={onToggle}>
                        <p>
                            The barcodes we supply are accepted in nearly every store worldwide, however there are a few
                            exceptions.
                        </p>
                        <p>
                            Every country and every different retailer has its own policy around barcode numbers which
                            is subject to change at any point in time. You will need to check with the retailer where
                            you plan to sell your products, to find out what their barcode requirements are.
                        </p>
                        <p>Here's a couple of examples of what they may tell you:</p>
                        <ul>
                            <li>
                                If they tell you that you need a "Barcode", "EAN Barcode" or "UPC Barcode" then
                                BarcodeBee is perfect for you.
                            </li>
                            <li>
                                If they tell you that you need a barcode starting with particular numbers or that it
                                must originate from a specific country, then you will need to obtain a Certificate of
                                Ownership directly from GS1.
                            </li>
                        </ul>
                        <p>
                            We cannot guarantee that every retailer worldwide will accept our barcodes, as nor can any
                            other barcode seller as each retailer has their own barcode requirements which are subject
                            to change at any point in time.
                        </p>
                        <p>
                            We have sold thousands of barcodes worldwide which work perfectly for our customers. However
                            please note that it is your responsibility to check the with the retailer you intend to
                            supply your products with, to be aware of their individual barcode policy.
                        </p>
                    </AccordionItem>
                    <AccordionItem
                        open={active}
                        id="ai-8"
                        title="Will your barcodes work for all different kinds of retail products?"
                        onToggle={onToggle}>
                        <p>
                            That's correct, with the exception of books and magazines as they do not use an EAN-13 or
                            UPC Barcode. The barcodes we supply will work perfectly for every other type of product
                            which uses an EAN-13 or UPC barcode.
                        </p>
                    </AccordionItem>
                    <AccordionItem
                        open={active}
                        id="ai-10"
                        title="Will my barcode numbers be unique?"
                        onToggle={onToggle}>
                        <p>
                            Yes, the barcode numbers we sell come from the UCC (GS1 US) and we guarantee that every
                            barcode number sold by us is unique having never been used or distributed by us in the past.
                        </p>
                        <p>
                            You will also be supplied with a Certificate of Authenticity & Ownership which shows that
                            you are the sole legal owner of that barcode number.
                        </p>
                    </AccordionItem>
                    <AccordionItem
                        open={active}
                        id="ai-11"
                        title="What country code will my barcodes have?"
                        onToggle={onToggle}>
                        <p>
                            Every barcode number that we sell has originated from the United States, and all EAN-13
                            barcode numbers we supply start with the numbers "06" or "07". This means that the barcodes
                            themselves originate from the United States, however have absolutely nothing to do with the
                            products associated with them.
                        </p>
                        <p>
                            Any product in any country can use barcodes that originate from the US (or any country),
                            just as US products can use barcodes that originate from different countries with different
                            prefixes.
                        </p>
                    </AccordionItem>
                    <AccordionItem
                        open={active}
                        id="ai-7"
                        title="What is the difference between UPC and EAN barcodes?"
                        onToggle={onToggle}>
                        <p>
                            The difference between EAN and UPC barcodes are the number of digits. The UPC is 12 digits,
                            and the EAN is 13 digits. If you look at an EAN & UPC barcode you will see that they are
                            exactly the same, except that than EAN has a "0" in-front.
                        </p>
                        <p>
                            The UPC barcode was originally created for use within the United States & Canada, where as
                            the EAN barcode was originally created for international use outside of North America, but
                            is now accepted worldwide.
                        </p>
                    </AccordionItem>
                </Container>
            </div>
        </PageTemplate>
    );
};

export const WhatToKnow = WhatToKnowInternal;
