import React from 'react';
import { connect } from 'react-redux';
import * as Api from '@core/store/effects';
import { FormField } from '@core/ui/atoms/FormField';
import { Button } from '@core/ui/atoms/Button';
import style from './ChangeNameForm.scss';
import { AllProps, StateProps, DispatchProps, FormData, FormErrors } from './ChangeNameForm.interface';
import { AppState } from '@core/common/reducer';
import { createLoadingSelector } from '@core/store/selectors/request/loadingSelector';
import { createErrorMessageSelector } from '@core/store/selectors/request/errorsSelector';
import { ChangeNameTypes } from '@core/store/effects/User';
import { createSuccessSelector } from '@core/store/selectors/request/successSelector';

const ChangeNameFormInternal: React.FC<AllProps> = ({ changeName, auth, isFetching, requestErrors, success }) => {
    const { user } = auth;
    const [data, setData] = React.useState<FormData>({
        firstName: user ? user.firstName : '',
        lastName: user ? user.lastName : ''
    });
    const [errors, setErrors] = React.useState<FormErrors>({});

    // Update request errors when they come through
    React.useEffect(() => {
        setErrors(requestErrors);
    }, [requestErrors]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    const validate = (): FormErrors => {
        const formErrors: FormErrors = {};

        if (!data.firstName) {
            formErrors.firstName = 'First name is required';
        }

        if (!data.lastName) {
            formErrors.lastName = 'Last name is required';
        }

        return formErrors;
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const formErrors = validate();
        setErrors(formErrors);
        if (Object.keys(formErrors).length === 0 && user) {
            changeName(data);
        }
    };

    return (
        <div className={style.editDetailsForm}>
            <form onSubmit={onSubmit}>
                <FormField
                    type="text"
                    name="firstName"
                    placeholder="First name"
                    value={data.firstName}
                    onChange={onChange}
                    error={errors.firstName}
                />
                <FormField
                    type="text"
                    name="lastName"
                    placeholder="Last name"
                    value={data.lastName}
                    onChange={onChange}
                    error={errors.lastName}
                />
                <Button text="Save" loading={isFetching} type="submit" />
            </form>
            {success && <div className={style.success}>Success, your legal name has been updated.</div>}
        </div>
    );
};

const successSelector = createSuccessSelector([ChangeNameTypes.BASE]);
const loadingSelector = createLoadingSelector([ChangeNameTypes.BASE]);
const errorsSelector = createErrorMessageSelector([ChangeNameTypes.BASE]);

const mapStateToProps = (state: AppState): StateProps => ({
    success: successSelector(state),
    auth: state.auth,
    isFetching: loadingSelector(state),
    requestErrors: errorsSelector(state)
});

const mapDispatchToProps: DispatchProps = {
    changeName: Api.User.ChangeName
};

const ChangeNameForm = connect(mapStateToProps, mapDispatchToProps)(ChangeNameFormInternal);

export { ChangeNameForm };
