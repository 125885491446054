import React from 'react';
import * as Api from '@core/store/effects';
import { PrivateRoute } from '@core/ui/molecules/hocs/PrivateRoute';
import style from './AccountCertificateDownload.scss';
import { PageTemplate } from '@core/ui/templates';
import { UserModel } from '@core/interface/models/user';
import { AppState } from '@core/common/reducer';
import { connect } from 'react-redux';
import { Container } from '@core/ui/atoms/Container';
import { Loading } from '@core/ui/atoms/Loading';
import { createLoadingSelector } from '@core/store/selectors/request/loadingSelector';
import { PaymentsReducerState } from '@core/store/reducers/PaymentsReducer';
import { PaymentModel } from '@core/interface/models/payment';
import { getDateFromTimestamp } from '@core/utils/date';

const options = {
    body: {},
    header: {
        include: false,
        theme: 'solid' as 'solid'
    },
    footer: {
        include: false
    },
    seo: {
        title: 'Certificate of Authenticity - BarcodeBee',
        meta: [
            {
                name: 'description',
                content: 'Certificate of Authenticity - BarcodeBee'
            }
        ]
    }
};

interface StateProps {
    user?: UserModel;
    payments: PaymentsReducerState;
    paymentsLoading: boolean;
}

interface DispatchProps {
    fetchUserPayments: () => void;
}

const AccountCertificateDownloadInternal: React.FC<StateProps & DispatchProps> = ({
    user,
    payments,
    paymentsLoading,
    fetchUserPayments
}) => {
    const [certificatePayment, setCertificatePayment] = React.useState<PaymentModel | null>(null);
    const [error, setError] = React.useState<boolean | null>(null);

    React.useEffect(() => {
        if (!payments.list) {
            fetchUserPayments();
        }
    }, []);

    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('identifier');

        if (payments.list && !!id) {
            const payment = payments.list.find(p => p.paymentId === id);
            if (payment) {
                setCertificatePayment(payment);
                setError(false);
            } else {
                setError(true);
            }
        } else {
            setError(true);
        }
    }, [payments]);

    React.useEffect(() => {
        if (certificatePayment) {
            setTimeout(() => {
                window.print();
            }, 500);
        }
    }, [certificatePayment]);

    const showLoader = !user || paymentsLoading || typeof error !== 'boolean';

    return (
        <PageTemplate options={options}>
            <div className={style.component}>
                <Container>
                    {showLoader ? (
                        <Loading color="PRIMARY" size={10} />
                    ) : error === true || !certificatePayment ? (
                        <div className={style.errorWrap}>
                            <h3>Something went wrong</h3>
                            <p>
                                It looks like something went wrong, please close this page and try again. If you are
                                still encountering issues trying to download your certificate please contact support.
                            </p>
                        </div>
                    ) : (
                        <div className={style.certificate}>
                            <h1>Certificate of Authenticity</h1>
                            <p>
                                BarcodeBee certifies that the barcode number(s) listed below are unique and originally
                                issued by the UCC, now known as GS1. BarcodeBee hereby assigns and transfers all
                                ownership of the barcode number(s) listed below to {user ? user.displayName : ''}.
                            </p>
                            <div className={style.numbers}>
                                <h6>
                                    {certificatePayment.barcodes ? certificatePayment.barcodes.length : ''} Barcode
                                    Numbers:
                                </h6>
                                <p>
                                    {certificatePayment.barcodes &&
                                        certificatePayment.barcodes.map((b, i) => {
                                            return `${b}${
                                                certificatePayment.barcodes &&
                                                i !== certificatePayment.barcodes.length - 1
                                                    ? ', '
                                                    : ''
                                            }`;
                                        })}
                                </p>
                            </div>
                            <p>
                                BarcodeBee maintains a secure database of all barcode numbers that have been assigned &
                                transferred to our customers. This ensures that all barcode numbers we issue are unique
                                and owned by you.
                            </p>
                            <div className={style.footer}>
                                <div className={style.left}>
                                    <div>Leigh Carman</div>
                                    <div>BarcodeBee</div>
                                    <div>Date Transferred: {getDateFromTimestamp(certificatePayment.created)}</div>
                                </div>
                                <div className={style.right}>
                                    <div>support@barcodebee.com</div>
                                    <div>https://www.barcodebee.com</div>
                                </div>
                            </div>
                        </div>
                    )}
                </Container>
            </div>
        </PageTemplate>
    );
};

const loadingSelector = createLoadingSelector([Api.Payments.FetchUserPaymentsTypes.BASE]);

const mapStateToProps = (state: AppState): StateProps => {
    return {
        user: state.auth.user,
        payments: state.payments,
        paymentsLoading: loadingSelector(state)
    };
};

const mapDispatchToProps: DispatchProps = {
    fetchUserPayments: Api.Payments.FetchUserPayments
};

const enhance = connect(mapStateToProps, mapDispatchToProps)(AccountCertificateDownloadInternal);

export const AccountCertificateDownload = PrivateRoute(enhance);
