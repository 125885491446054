import { Dispatch } from 'redux';
import { AppApi } from '@core/api';
import { AppState } from '@core/common/reducer';
import { ForgotPasswordTypes } from './Types';
import { ForgotPasswordPayload, ForgotPasswordResponse } from './Interface';

const RequestForgotPassword = (data: ForgotPasswordPayload) => {
    return async (dispatch: Dispatch, getState: () => AppState, { requestApi }: AppApi) => {
        try {
            dispatch({
                type: ForgotPasswordTypes.FORGOT_PASSWORD_REQUEST
            });
            const res = await requestApi.post<ForgotPasswordResponse>('/auth/forgot_password', data);
            dispatch({
                type: ForgotPasswordTypes.FORGOT_PASSWORD_SUCCESS,
                payload: res.data
            });
            return { success: true };
        } catch (err) {
            dispatch({
                type: ForgotPasswordTypes.FORGOT_PASSWORD_FAILURE,
                payload: err.response.data
            });
            return { success: false };
        }
    };
};

export { RequestForgotPassword };
