import React from 'react';
import style from './About.scss';
import { PageTemplate } from '@core/ui/templates';
import { Container } from '@core/ui/atoms/Container';
import { CallToAction } from '@core/ui/molecules/global/CallToAction';
import teamSpiritImage from '@core/assets/images/ud_team_spirit.svg';

const options = {
    body: {},
    header: {
        include: true,
        theme: 'solid' as 'solid'
    },
    footer: {
        include: true
    },
    seo: {
        title: 'About Us - BarcodeBee',
        meta: [
            {
                name: 'description',
                content: 'About Us - BarcodeBee'
            }
        ]
    }
};

const AboutInternal = () => (
    <PageTemplate options={options}>
        <div className={style.component}>
            <Container>
                <h6>BarcodeBee</h6>
                <h1>About us</h1>
                <div className={style.aboutSection}>
                    <div className={style.left}>
                        <img src={teamSpiritImage} width={500} />
                    </div>
                    <div className={style.right}>
                        <p>Tech, product and customer focussed.</p>
                        <p>
                            We believe that barcodes should be affordable for all businesses. Affordable without having
                            to purchase your barcodes in bulk, or pay hefty annual fees whether you are a small or large
                            business. Our small team is dedicated to becoming Australias number one solution that
                            business can rely on to legally own their own barcodes at an affordable price.
                        </p>
                        <p>
                            All of the barcodes numbers that BarcodeBee owns and sells, originated from the UCC (Uniform
                            Code Coucil) now known as GS1, prior to August 28, 2002.
                        </p>
                        <p>
                            Our friendly team is ready to help you get barcodes for your products, and get your products
                            into stores, so what are you waiting for? Get started today!
                        </p>
                    </div>
                </div>
                <CallToAction />
            </Container>
        </div>
    </PageTemplate>
);

export const About = AboutInternal;
