import { AuthReducerState } from '@core/store/reducers/AuthReducer';
import { SignupResponse } from '@core/store/effects/Auth';

export const signupSuccess = (state: AuthReducerState, payload: SignupResponse): AuthReducerState => {
    const { user } = payload;
    return {
        ...state,
        isAuthenticated: true,
        user
    };
};
