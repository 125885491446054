import { AppRoute } from '@core/common/routes';
import { About } from './About';
import { Paths } from '@core/common/paths';

export const aboutRoute: AppRoute[] = [
    {
        path: Paths.main.about,
        exact: true,
        component: About
    }
];
