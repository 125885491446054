import React from 'react';
import classes from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Paths } from '@core/common/paths';
import style from './Menu.scss';
import { AppState } from '@core/common/reducer';

interface StateProps {
    isAuthenticated: boolean;
}

interface ComponentProps extends StateProps {
    theme?: 'solid';
}

const MenuInternal: React.FC<ComponentProps> = ({ isAuthenticated, theme }) => {
    const [path, setPath] = React.useState('');

    React.useEffect(() => {
        if (window) {
            setPath(window.location.pathname);
        }
    }, []);

    return (
        <ul className={classes(style.nav, theme === 'solid' && style.themeSolid)}>
            <li>
                <Link className={classes(style.navItem, path === '/' && style.active)} to={Paths.main.home}>
                    <div className={style.link}>Overview</div>
                </Link>
            </li>
            <li>
                <Link className={classes(style.navItem, path === '/pricing' && style.active)} to={Paths.main.pricing}>
                    <div className={style.link}>Pricing</div>
                </Link>
            </li>
            <li>
                <Link
                    className={classes(style.navItem, path === '/whattoknow' && style.active)}
                    to={Paths.resources.whattoknow}>
                    <div className={style.link}>The FAQ</div>
                </Link>
            </li>
        </ul>
    );
};

const mapStateToProps = ({ auth }: AppState): StateProps => {
    return {
        isAuthenticated: auth.isAuthenticated
    };
};

export const Menu = connect<StateProps>(mapStateToProps)(MenuInternal);
