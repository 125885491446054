import { Reducer } from 'redux';
import { SuccessReducerState } from './Interface';

const initialState: SuccessReducerState = {};

interface AnyAction {
    type: string;
}

export const successReducer: Reducer<SuccessReducerState, AnyAction> = (
    state = initialState,
    action
): SuccessReducerState => {
    const { type } = action;
    const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

    // If it's not a *_REQUEST / *_FAILURE action, ignore
    if (!matches) {
        return state;
    }

    const [, requestName, requestState] = matches;

    return {
        ...state,
        [requestName]: requestState === 'SUCCESS'
    };
};
