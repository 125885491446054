import React from 'react';
import { connect } from 'react-redux';
import { PrivateRoute } from '@core/ui/molecules/hocs/PrivateRoute';
import { ChangeNameForm } from '@core/ui/molecules/forms/ChangeNameForm';
import { ChangeEmailForm } from '@core/ui/molecules/forms/ChangeEmailForm';
import style from './AccountPersonal.scss';
import { PageTemplate } from '@core/ui/templates';
import { SectionDrop } from '@core/ui/molecules/pages/dash/SectionDrop';
import { AppState } from '@core/common/reducer';
import { UserModel } from '@core/interface/models/user';
import { ChangePasswordForm } from '@core/ui/molecules/forms/ChangePasswordForm';
import { EmailConfirmed } from '@core/ui/molecules/pages/dash/EmailConfirmed';
import { getDaysTextFromNumber, getDaysFromTimestamp } from '@core/utils/date';
import { Container } from '@core/ui/atoms/Container';
import { Loading } from '@core/ui/atoms/Loading';
import { AccountHead } from '@core/ui/molecules/pages/dash/AccountHead';
import { Paths } from '@core/common/paths';

const options = {
    body: {},
    header: {
        include: true,
        theme: 'solid' as 'solid'
    },
    footer: {
        include: true
    },
    seo: {
        title: 'Account Info - BarcodeBee',
        meta: [
            {
                name: 'description',
                content: 'Account Info - BarcodeBee'
            }
        ]
    }
};

interface StateProps {
    user?: UserModel;
}

interface ComponentProps extends StateProps {}

const AccountPersonalInternal: React.FC<ComponentProps> = ({ user }) => {
    if (!user) {
        return null;
    }

    const days = getDaysTextFromNumber(getDaysFromTimestamp(user.metadata.updatedAt.password));

    return (
        <PageTemplate options={options}>
            <div className={style.component}>
                <Container>
                    {!user ? (
                        <Loading color="PRIMARY" size={10} />
                    ) : (
                        <>
                            <AccountHead title="Personal Info" active={Paths.account.personal} />
                            <div className={style.dashboardPage}>
                                <SectionDrop title="Legal name" description={user.displayName} actionText="Edit">
                                    <ChangeNameForm />
                                </SectionDrop>
                                <SectionDrop title="Email address" description={user.email} actionText="Edit">
                                    <ChangeEmailForm />
                                </SectionDrop>
                                <SectionDrop
                                    title="Password"
                                    description={`Password last updated ${days} ago`}
                                    actionText="Update">
                                    <ChangePasswordForm />
                                </SectionDrop>
                                <SectionDrop
                                    title="Email Status"
                                    description={`You ${
                                        user.emailVerified ? 'have' : 'have not'
                                    } confirmed your email ${user.email}`}
                                    actionText="View">
                                    <EmailConfirmed />
                                </SectionDrop>
                            </div>
                        </>
                    )}
                </Container>
            </div>
        </PageTemplate>
    );
};

const mapStateToProps = ({ auth }: AppState): StateProps => {
    return {
        user: auth.user
    };
};

const enhance = connect(mapStateToProps)(AccountPersonalInternal);

export const AccountPersonal = PrivateRoute(enhance);
