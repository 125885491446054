import { AppRoute } from '@core/common/routes';
import { Terms } from './Terms';
import { Paths } from '@core/common/paths';

export const termsRoute: AppRoute[] = [
    {
        path: Paths.legal.terms,
        exact: true,
        component: Terms
    }
];
