import { Reducer } from 'redux';
import * as Api from '../../effects';
import { AuthReducerState } from './Interface';
import { loginSuccess } from '@core/store/handlers/Auth/loginSuccess';
import { logoutSuccess } from '@core/store/handlers/Auth/logoutSuccess';
import { signupSuccess } from '@core/store/handlers/Auth/signupSuccess';
import { fetchCurrentUserSuccess } from '@core/store/handlers/Auth/fetchCurrentUserSuccess';
import { fetchCurrentUserFailure } from '@core/store/handlers/Auth/fetchCurrentUserFailure';
import { changeNameSuccess } from '@core/store/handlers/Auth/changeNameSuccess';
import { changeEmailSuccess } from '@core/store/handlers/Auth/changeEmailSuccess';
import { verifyConfirmationEmailTokenSuccess } from '@core/store/handlers/Auth/verifyConfirmationEmailTokenSuccess';
import { toggleAuthSuccess } from '@core/store/handlers/Auth/toggleAuthSuccess';

const initialState: AuthReducerState = {
    isAuthenticated: false
};

type AuthActions =
    | Api.Auth.LoginAction
    | Api.Auth.SignupAction
    | Api.Auth.LogoutAction
    | Api.User.FetchCurrentUserAction
    | Api.User.ChangeNameAction
    | Api.User.ChangeEmailAction
    | Api.User.VerifyConfirmationEmailTokenAction
    | Api.Auth.ToggleAuthAction;

const authReducer: Reducer<AuthReducerState, AuthActions> = (state = initialState, action): AuthReducerState => {
    switch (action.type) {
        // Login success
        case Api.Auth.LoginTypes.LOGIN_SUCCESS:
            return loginSuccess(state, action.payload);
        // Signup success
        case Api.Auth.SignupTypes.SIGNUP_SUCCESS:
            return signupSuccess(state, action.payload);
        // Logout success
        case Api.Auth.LogoutTypes.LOGOUT_SUCCESS:
            return logoutSuccess(state, action.payload);
        // Fetch current user success
        case Api.User.FetchCurrentUserTypes.FETCH_CURRENT_USER_SUCCESS:
            return fetchCurrentUserSuccess(state, action.payload);
        // Fetch current user failure
        case Api.User.FetchCurrentUserTypes.FETCH_CURRENT_USER_FAILURE:
            return fetchCurrentUserFailure(state, action.payload);
        // Change name success
        case Api.User.ChangeNameTypes.CHANGE_NAME_SUCCESS:
            return changeNameSuccess(state, action.payload);
        // Change email success
        case Api.User.ChangeEmailTypes.CHANGE_EMAIL_SUCCESS:
            return changeEmailSuccess(state, action.payload);
        // Verify confirmation email token success
        case Api.User.VerifyConfirmationEmailTokenTypes.VERIFY_CONFIRMATION_EMAIL_TOKEN_SUCCESS:
            return verifyConfirmationEmailTokenSuccess(state, action.payload);
        // Toggle auth modal
        case Api.Auth.ToggleAuthTypes.TOGGLE_AUTH_SUCCESS:
            return toggleAuthSuccess(state, action.payload);
        default: {
            return state;
        }
    }
};

export { authReducer };
