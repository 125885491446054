import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Api from '@core/store/effects';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { StripeLoader } from '../StripeLoader';
import { Button } from '@core/ui/atoms';
import { AllProps, StateProps, DispatchProps } from './StripeForm.interface';
import style from './StripeForm.scss';
import { AppState } from '@core/common/reducer';
import { createLoadingSelector } from '@core/store/selectors/request/loadingSelector';
import { createErrorMessageSelector } from '@core/store/selectors/request/errorsSelector';
import { StripePaymentTypes } from '@core/store/effects/Payments';
import { Colors } from '@core/interface/styles/colors';
import { Typography } from '@core/interface/styles/typography';
import { mapBarcodeQuantityToPrice } from '@core/utils/helpers/mapBarcodeQuantityToPrice';
import { Modal } from '@core/ui/atoms/Modal';
import { Loading } from '@core/ui/atoms/Loading';

const colors = require('@core/assets/styles/colors.scss') as Colors;
const typography = require('@core/assets/styles/typography.scss') as Typography;

const stripeStyle = {
    base: {
        backgroundColor: colors.baseWhite,
        color: colors.textDark,
        fontSize: '16px',
        fontFamily: typography.fontPrimary,
        fontSmoothing: 'antialiased',
        '::placeholder': {
            color: colors.textLight
        }
    }
};

const PaymentFormInternal: React.FC<AllProps> = ({
    stripe,
    requestStripePayment,
    requestStripePaymentInit,
    requestStripePaymentError,
    isFetching,
    requestErrors,
    history,
    user,
    packageQuantity
}) => {
    const [hasFocus, setHasFocus] = React.useState(false);

    if (!user || !packageQuantity) {
        return null;
    }

    const priceInCents = mapBarcodeQuantityToPrice(packageQuantity);

    if (!priceInCents) {
        return null;
    }

    const onChange = () => {
        if (requestErrors.request) {
            requestStripePaymentError({});
        }
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        // Proceed if there are no present errors
        if (!requestErrors.request) {
            // Sets loading state in store
            requestStripePaymentInit();

            // Create the strip token sent to endpoint
            const stripeToken = await stripe.createToken();

            if (stripeToken.error) {
                // Set the loading state back in store
                requestStripePaymentError({
                    request: {
                        code: 'payments/invalid-submission',
                        message: stripeToken.error.message || 'Something went wrong'
                    }
                });
            } else if (stripeToken.token && stripeToken.token.card) {
                const payload = {
                    name: user.displayName,
                    email: user.email,
                    description: `${packageQuantity} ${packageQuantity === 1 ? 'Barcode' : 'Barcodes'}`,
                    stripeToken: stripeToken.token.id,
                    amount: priceInCents,
                    quantity: packageQuantity,
                    cardId: stripeToken.token.card.id
                };
                requestStripePayment(payload);
            }
        }
    };

    return (
        <div className={style.stripeForm}>
            <div className={style.paymentForm}>
                <div className={classNames(style.cardElement, hasFocus && style.cardElementFocus)}>
                    <CardElement
                        onChange={onChange}
                        onFocus={() => setHasFocus(true)}
                        onBlur={() => setHasFocus(false)}
                        style={stripeStyle}
                    />
                </div>
                <div className={style.paymentButton}>
                    <Button extend text="Pay" onClick={onSubmit} loading={isFetching} />
                </div>
            </div>
            {!!Object.keys(requestErrors).length && (
                <div className={style.cardError}>{requestErrors.request.message}</div>
            )}
            <Modal open={isFetching}>
                <div className={style.loading}>
                    <h6>Processing, please wait...</h6>
                    <Loading color="SECONDARY" size={12} />
                </div>
            </Modal>
        </div>
    );
};

const StripeForm: React.FC = () => {
    return (
        <StripeLoader>
            <PaymentForm />
        </StripeLoader>
    );
};

const loadingSelector = createLoadingSelector([StripePaymentTypes.BASE]);
const errorsSelector = createErrorMessageSelector([StripePaymentTypes.BASE]);

const mapStateToProps = (state: AppState): StateProps => ({
    isFetching: loadingSelector(state),
    requestErrors: errorsSelector(state),
    user: state.auth.user,
    packageQuantity: state.checkout.quantityChosen
});

const mapDispatchToProps: DispatchProps = {
    requestStripePayment: Api.Payments.StripeExecutePayment,
    requestStripePaymentInit: Api.Payments.StripeExecutePaymentInit,
    requestStripePaymentError: Api.Payments.StripeExecutePaymentError
};

const PaymentForm = injectStripe<Anything>(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentFormInternal))
);

export { StripeForm };
