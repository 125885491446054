import React from 'react';
import { Icon } from '@core/ui/atoms/Icon';
import style from './Checkbox.scss';

interface Props {
    id: string;
    name: string;
    value: boolean;
    onChange: () => void;
    text?: string;
}

const Checkbox: React.FC<Props> = ({ id, name, value, onChange, text }) => (
    <label className={style.checkbox}>
        <input type="checkbox" id={id} defaultChecked={value} onClick={onChange} name={name} />
        <span className={style.check}>
            <Icon name="check" size="14" />
        </span>
        {text && <span className={style.text}>{text}</span>}
    </label>
);

export { Checkbox };
