import React from 'react';
import { Icon } from '@core/ui/atoms/Icon';
import style from './CircularIcon.scss';

interface Props {
    icon: string;
    size?: string;
}

const CircularIcon: React.FC<Props> = ({ icon, size }) => (
    <div className={style.circularIcon}>
        <div className={style.circle}>
            <Icon name={icon} size={size || '24px'} />
        </div>
    </div>
);

export { CircularIcon };
