export interface AppConfig {
    env: string;
    isDev: boolean;
    apiUrl: string;
    siteUrl: string;
    useRender: boolean;
    siteName: string;
    isBrowser: boolean;
    stripePublicKey: string;
    paypalPublicKey: string;
    fonts: {
        primary: string;
    };
    locale: string | null;
}

type Environment = 'common' | 'development' | 'uat' | 'production';

type Config = { [key in Environment]: Partial<AppConfig> };

const isBrowser = process && process['browser'];

const getEnv = (): string => {
    if (process.env.NODE_ENV) {
        return process.env.NODE_ENV;
    }
    if (typeof window !== 'undefined') {
        if (window.location.hostname === 'barcodebee.com') {
            return 'production';
        }
        if (
            window.location.hostname === 'barcodebee-prod.web.app' ||
            window.location.hostname === 'barcodebee-prod.firebaseapp.com'
        ) {
            return 'uat';
        }
        return 'development';
    }
    return '';
};

// console.log('Environment: ', JSON.stringify(process.env));

const env = getEnv();

const getLocale = () => {
    if (typeof window !== 'undefined') {
        const locale = window.location.pathname.split('/')[1];

        if (locale === 'au') {
            return 'au';
        }
    }
    return null;
};

/**
 * Note: Full URL for apiUrl is needed otherwise Firebase will treat the `/api` request as
 * 127.0.0.1 (localhost) and not route it to the backend.
 */
const defaultConfig: Config = {
    common: {
        env,
        isDev: env === 'development',
        isBrowser,
        useRender: Boolean(isBrowser && localStorage.getItem('useRender')),
        siteName: 'BarcodeBee',
        fonts: {
            primary:
                'https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400&family=Tangerine:wght@700&display=swap'
        },
        locale: getLocale()
    },
    development: {
        apiUrl: 'http://localhost:3001/api',
        siteUrl: 'http://localhost:3001',
        stripePublicKey:
            'pk_test_51HMj8BALzqoyXILbuHCTRS6JyEVbi6qtsszeKvJbLVPabYbx3q5JGwDl4AW1qbGmJ2mVorGy5XmMJumsxx5o6F7P00iWYK5VBg',
        paypalPublicKey: 'AX5UY3TkEARAlx8FBJSPnIN_3Udak5z5rk3SO2dJZN3tgmItOwZk6ejVAL3vjwfjkeJhWfiFmb3De_1B'
    },
    uat: {
        apiUrl: 'https://barcodebee-dev.web.app/api',
        siteUrl: 'https://barcodebee-dev.web.app',
        stripePublicKey:
            'pk_test_51HMj8BALzqoyXILbuHCTRS6JyEVbi6qtsszeKvJbLVPabYbx3q5JGwDl4AW1qbGmJ2mVorGy5XmMJumsxx5o6F7P00iWYK5VBg',
        paypalPublicKey: 'AX5UY3TkEARAlx8FBJSPnIN_3Udak5z5rk3SO2dJZN3tgmItOwZk6ejVAL3vjwfjkeJhWfiFmb3De_1B'
    },
    production: {
        apiUrl: 'https://www.barcodebee.com/api',
        siteUrl: 'https://www.barcodebee.com',
        stripePublicKey:
            'pk_live_51HMj8BALzqoyXILbs1ZtIh3yhwNm0Ah3XRq6XHlr711rQfqOhCrnhpvdeahMfeZpZKm5PB4h9JhMujG2nCt90gDU00kdTNOHSw',
        paypalPublicKey: 'AWr2mw4PaTVwZmqDa0jZ6_sKdttwlDcajjxmNN1XKO3o-5_tLYK16NR0azdA7BTv2Zby-UJaIW15LSNQ'
    }
};

export const config: AppConfig = { ...defaultConfig.common, ...defaultConfig[env] };
