import { Dispatch } from 'redux';
import { AppApi } from '@core/api';
import { AppState } from '@core/common/reducer';
import { FetchUserPaymentsTypes } from './Types';
import { FetchUserPaymentsResponse } from './Interface';

const FetchUserPayments = () => {
    return async (dispatch: Dispatch, getState: () => AppState, { requestApi }: AppApi) => {
        try {
            dispatch({
                type: FetchUserPaymentsTypes.FETCH_USER_PAYMENTS_REQUEST
            });
            const res = await requestApi.get<FetchUserPaymentsResponse>('/payments');
            dispatch({
                type: FetchUserPaymentsTypes.FETCH_USER_PAYMENTS_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: FetchUserPaymentsTypes.FETCH_USER_PAYMENTS_FAILURE,
                payload: err.response.data
            });
        }
    };
};

export { FetchUserPayments };
