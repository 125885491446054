/// <reference types="webpack-env" />
import { applyMiddleware, createStore, Store } from 'redux';
import thunk from 'redux-thunk';
import { Request } from 'express';

import { initializeApi } from '@core/api';
import { reducer, AppState } from './reducer';
import { config } from './config';

export function configureStore(initialState: AppState | undefined, req?: Request): Store<AppState> {
    const api = initializeApi(config.apiUrl, req);

    let middleware = applyMiddleware(thunk.withExtraArgument(api));

    if (config.isDev) {
        const { composeWithDevTools } = require('redux-devtools-extension');
        middleware = composeWithDevTools(middleware);
    }

    const store = createStore(reducer, initialState!, middleware);

    if (module.hot) {
        module.hot.accept('./reducer', () => {
            const { reducer: neextReducer } = require('./reducer');
            store.replaceReducer(neextReducer);
        });
    }

    return store as Store<AppState>;
}
