import { Reducer } from 'redux';
import { ErrorsReducerState } from './Interface';

const initialState: ErrorsReducerState = {};

interface AnyAction {
    type: string;
    payload: {
        errors: {
            [key: string]: string;
        };
    };
}
export const errorsReducer: Reducer<ErrorsReducerState, AnyAction> = (
    state = initialState,
    action
): ErrorsReducerState => {
    const { type, payload } = action;
    const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);

    // If it's not a *_REQUEST / *_FAILURE action, ignore
    if (!matches) {
        return state;
    }

    const [, requestName, requestState] = matches;

    return {
        ...state,
        [requestName]: requestState === 'FAILURE' ? payload.errors : {}
    };
};
