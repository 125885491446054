import React from 'react';
import { PublicRoute } from '@core/ui/molecules/hocs/PublicRoute';
import { SignupForm } from '@core/ui/molecules/forms/SignupForm';
import style from './Signup.scss';
import { PageTemplate } from '@core/ui/templates';
import { Container } from '@core/ui/atoms/Container';

const options = {
    body: {},
    header: {
        include: true,
        theme: 'solid' as 'solid'
    },
    footer: {
        include: true
    },
    seo: {
        title: 'Signup - BarcodeBee',
        meta: [
            {
                name: 'description',
                content: 'Signup - BarcodeBee'
            }
        ]
    }
};

const SignupInternal = () => (
    <PageTemplate options={options}>
        <div className={style.component}>
            <Container>
                <SignupForm />
            </Container>
        </div>
    </PageTemplate>
);

export const Signup = PublicRoute(SignupInternal);
