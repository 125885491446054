import React, { useState, useEffect } from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { Loading } from '@core/ui/atoms/Loading';
import { config } from '@core/common/config';
import { uuid } from '@core/utils/helpers/uuid';

interface ScriptLoaded {
    success: boolean;
    error?: boolean;
}

const StripeLoader: React.FC = ({ children }) => {
    const [stripeLoaded, setStripeLoaded] = useState<ScriptLoaded>({
        success: false
    });

    /**
     * TODO: Stripe is being loaded multiple times every time the component mounts, by switching
     * back to this page the script gets added to the head again. Instead dispatch an action
     * which tells us that stripe has been added.
     */
    useEffect(() => {
        const uniqueId = uuid();
        const scriptElement = document.getElementById(uniqueId);

        if (!scriptElement) {
            const script = document.createElement('script');
            script.src = 'https://js.stripe.com/v3/';
            script.id = uniqueId;

            const scriptLoadSuccess = () => setStripeLoaded({ success: true });
            const scriptLoadFailure = () => setStripeLoaded({ success: false, error: true });

            script.addEventListener('load', scriptLoadSuccess, { once: true });
            script.addEventListener('error', scriptLoadFailure, { once: true });
            document.head.appendChild(script);
        } else {
            setStripeLoaded({ success: true });
        }
    }, []);

    return stripeLoaded.success ? (
        <StripeProvider apiKey={config.stripePublicKey}>
            <Elements fonts={[{ cssSrc: config.fonts.primary }]}>{children}</Elements>
        </StripeProvider>
    ) : (
        <Loading color="PRIMARY" />
    );
};

export { StripeLoader };
