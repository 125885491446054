import { AppRoute } from '@core/common/routes';
import { ResetPassword } from './ResetPassword';
import { Paths } from '@core/common/paths';

export const resetPasswordRoute: AppRoute[] = [
    {
        path: Paths.auth.resetPassword,
        exact: true,
        component: ResetPassword
    }
];
