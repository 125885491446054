import React from 'react';
import { Link } from 'react-router-dom';
import style from './Terms.scss';
import { config } from '@core/common/config';
import { Paths } from '@core/common/paths';
import { PageTemplate } from '@core/ui/templates';
import { Container } from '@core/ui/atoms/Container';

const options = {
    body: {},
    header: {
        include: true,
        theme: 'solid' as 'solid'
    },
    footer: {
        include: true
    },
    seo: {
        title: 'Terms & Conditions - BarcodeBee',
        meta: [
            {
                name: 'description',
                content: 'Terms & Conditions - BarcodeBee'
            }
        ]
    }
};

const Terms: React.FC = () => {
    return (
        <PageTemplate options={options}>
            <div className={style.termsPage}>
                <Container>
                    <div className={style.termsPageInner}>
                        <h1>Terms of Service</h1>
                        <h4>1. Terms</h4>
                        <p>
                            By accessing the BarcodeBee website at <Link to={Paths.main.home}>{config.siteUrl}</Link>{' '}
                            (the "site"), you are agreeing to be bound by these terms of service, all applicable laws
                            and regulations, and agree that you are responsible for compliance with any applicable local
                            laws. If you do not agree with any of these terms, you are prohibited from using or
                            accessing this site. The materials contained in this website are protected by applicable
                            copyright and trademark law.
                        </p>

                        <h4>2. Use License</h4>
                        <p>
                            Permission is granted to temporarily download one copy of the materials (information or
                            software) on {config.siteName}&apos;s website for personal, non-commercial transitory
                            viewing only. This is the grant of a license, not a transfer of title, and under this
                            license you may not:
                        </p>
                        <ol>
                            <li>modify or copy the materials;</li>
                            <li>
                                use the materials for any commercial purpose, or for any public display (commercial or
                                non-commercial);
                            </li>
                            <li>
                                attempt to decompile or reverse engineer any software contained on {config.siteName}
                                &apos;s website;
                            </li>
                            <li>remove any copyright or other proprietary notations from the materials; or</li>
                            <li>
                                transfer the materials to another person or "mirror" the materials on any other server.
                            </li>
                        </ol>
                        <p>
                            This license shall automatically terminate if you violate any of these restrictions and may
                            be terminated by {config.siteName} at any time. Upon terminating your viewing of these
                            materials or upon the termination of this license, you must destroy any downloaded materials
                            in your possession whether in electronic, digital or printed format.
                        </p>

                        <h4>3. Disclaimer</h4>
                        <p>
                            The materials on {config.siteName}&apos;s website are provided on an &apos;as is&apos;
                            basis. {config.siteName} makes no warranties, expressed or implied, and hereby disclaims and
                            negates all other warranties including, without limitation, implied warranties or conditions
                            of merchantability, fitness for a particular purpose, or non-infringement of intellectual
                            property or other violation of rights.
                        </p>
                        <p>
                            Further, {config.siteName} does not warrant or make any representations concerning the
                            accuracy, likely results, or reliability of the use of the materials on its website or
                            otherwise relating to such materials or on any sites linked to this site.
                        </p>

                        <h4>4. Indemnification</h4>
                        <p>
                            You agree to indemnify, defend and hold harmless BarcodeBee, its officers, directors,
                            employees, agents and third parties, for any losses, costs, liabilities and expenses
                            (including reasonable attorneys fees) relating to or arising out of your use of or inability
                            to use the site or services, any user postings made by you, your violation of any terms of
                            this Agreement or your violation of any rights of a third party, or your violation of any
                            applicable laws, rules or regulations. BarcodeBee reserves the right, at its own cost, to
                            assume the exclusive defense and control of any matter otherwise subject to indemnification
                            by you, in which event you will fully cooperate with BarcodeBee in asserting any available
                            defenses.
                        </p>

                        <h4>5. Governing Law</h4>
                        <p>
                            These terms and conditions are governed by and construed in accordance with the laws of
                            Australia and you irrevocably submit to the exclusive jurisdiction of the courts in any
                            State or location within Australia chosen by BarcodeBee.
                        </p>

                        <h4>6. Limitations</h4>
                        <p>
                            In no event shall {config.siteName} or its suppliers be liable for any damages (including,
                            without limitation, damages for loss of data or profit, or due to business interruption)
                            arising out of the use or inability to use the materials on {config.siteName}&apos;s
                            website, even if {config.siteName} or a {config.siteName} authorized representative has been
                            notified orally or in writing of the possibility of such damage. Because some jurisdictions
                            do not allow limitations on implied warranties, or limitations of liability for
                            consequential or incidental damages, these limitations may not apply to you.
                        </p>
                        <p>
                            Acceptance of your barcodes into stores, including but not limited to EAN/UPC numbers, and
                            all other aspects of your product are at the sole discretion of your product retailers.
                            BarcodeBee will not be liable for incidental or consequential damages, regardless of whether
                            such damages are foreseeable.
                        </p>
                        <p>
                            Most retailers do not require a certificate from GS1, however some retailers require that
                            you supply a certificate from GS1 and some require a GS1 certificate in your name. It is
                            your responsibility to check with your retailers to find out what their barcode requirements
                            are before you purchase one of our barcode packages.
                        </p>
                        <p>
                            BarcodeBee under no circumstances will be held responsible if you find out after your
                            purchase that any retailer you plan to work with requires that you have barcodes issued
                            directly from GS1, or that your company name shows in GS1's GEPIR database.
                        </p>
                        <p>
                            If any retailer you plan to work with requires a GS1 issued certificate, then you must
                            obtain your barcode numbers directly from GS1.
                        </p>

                        <h4>7. Accuracy of materials</h4>
                        <p>
                            The materials appearing on {config.siteName}&apos;s website could include technical,
                            typographical, or photographic errors. {config.siteName} does not warrant that any of the
                            materials on its website are accurate, complete or current. {config.siteName} may make
                            changes to the materials contained on its website at any time without notice. However{' '}
                            {config.siteName} does not make any commitment to update the materials.
                        </p>

                        <h4>8. Data Security</h4>
                        <p>
                            We use commercially reasonable efforts to protect credit card transactions through industry
                            standard SSL encryption and the use of secure internet payment services. In order to
                            complete a transaction, payment information may be transferred through credit card
                            processing companies who interact with your bank or credit card company. For your
                            protection, BarcodeBee does not retain any credit card information. Also, when you download
                            your barcode order your link is authenticated and encrypted to protect your information.
                        </p>

                        <h4>9. Links</h4>
                        <p>
                            {config.siteName} has not reviewed all of the sites linked to its website and is not
                            responsible for the contents of any such linked site. The inclusion of any link does not
                            imply endorsement by {config.siteName} of the site. Use of any such linked website is at the
                            user&apos;s own risk.
                        </p>

                        <h4>10. Termination & Access Restriction</h4>
                        <p>
                            BarcodeBee reserves the right, in its sole discretion, to terminate your access to the site
                            and the related services or any portion thereof at any time, without notice. To the maximum
                            extent permitted by law, this agreement is governed by the laws of Australia and you hereby
                            consent to the exclusive jurisdiction and venue of courts in Australia in all disputes
                            arising out of or relating to the use of the site. Use of the site is unauthorized in any
                            jurisdiction that does not give effect to all provisions of these Terms, including, without
                            limitation, this section.
                        </p>

                        <h4>11. Modifications</h4>
                        <p>
                            {config.siteName} may revise these Terms of Service for its website at any time without
                            notice. The most current version of the Terms of Service will supersede all previous
                            versions. By using this website you are agreeing to be bound by the then current version of
                            these Terms of Service.
                        </p>
                    </div>
                </Container>
            </div>
        </PageTemplate>
    );
};

export { Terms };
