import React from 'react';
import { connect } from 'react-redux';
import style from './PackageStep.scss';
import { Package } from '../Package/Package';
import { AppState } from '@core/common/reducer';
import { UserModel } from '@core/interface/models/user';

interface ComponentProps {}

interface StateProps {
    user?: UserModel;
}

const PackageStepInternal: React.FC<ComponentProps & StateProps> = ({ user }) => {
    return (
        <div className={style.component}>
            <div className={style.top}>
                <h6>Step 1</h6>
                <h3>Select your package</h3>
                <p>
                    You're just moments away from getting barcodes for your products! The first step is to select one of
                    our barcode packages below.
                </p>
            </div>
            <div className={style.packages}>
                <div className={style.head}>
                    <div className={style.quantity}>Quantity</div>
                    <div className={style.price}>Price</div>
                </div>
                <Package quantity={1} priceInCents={3495} />
                {
                    // START for Test Purposes
                }
                {user && user.uid === 's5IGs1ahzoSFrli68KfgXjEdeUl1' && (
                    <Package quantity={2} priceInCents={100} />
                )}
                {
                    // END for Test Purposes
                }
                <Package quantity={3} priceInCents={7495} />
                <Package quantity={5} priceInCents={10995} />
                <Package quantity={10} priceInCents={16995} />
                <Package quantity={25} priceInCents={23995} />
                <Package quantity={50} priceInCents={30995} />
                <Package quantity={100} priceInCents={47995} />
                <Package quantity={150} priceInCents={62995} />
                <Package quantity={200} priceInCents={71995} />
            </div>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    return {
        user: state.auth.user,
    };
};

const PackageStep = connect(mapStateToProps)(PackageStepInternal);

export { PackageStep };
