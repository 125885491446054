import React, { ReactNode } from 'react';
import styles from './Table.scss';

interface ComponentProps {
    children: ReactNode;
}

const TableHead: React.FC<ComponentProps> = ({ children }) => {
    return <div className={styles.tableHead}>{children}</div>;
};

const TableItem: React.FC<ComponentProps> = ({ children }) => {
    return <div className={styles.tableItem}>{children}</div>;
};

const Table: React.FC<ComponentProps> = ({ children }) => {
    return <div className={styles.table}>{children}</div>;
};

export { Table, TableHead, TableItem };
