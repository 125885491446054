import React from 'react';
import style from './TextSeparator.scss';

interface ComponentProps {
    text: string;
}

const TextSeparator: React.FC<ComponentProps> = ({ text }) => (
    <div className={style.separatorWrap}>
        <div className={style.separator}>{text}</div>
    </div>
);

export { TextSeparator };
