import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Api from '@core/store/effects';
import { Paths } from '@core/common/paths';
import { FormField } from '@core/ui/atoms/FormField';
import { Checkbox } from '@core/ui/atoms/Checkbox';
import { Button } from '@core/ui/atoms/Button';
import { CircularIcon } from '@core/ui/atoms/CircularIcon';
import { GlobalError } from '@core/ui/atoms/GlobalError';
import { AllProps, DispatchProps, FormErrors, FormData, StateProps } from './LoginForm.interface';
import style from './LoginForm.scss';
import main from '@core/assets/styles/global.scss';
import { createLoadingSelector } from '@core/store/selectors/request/loadingSelector';
import { AppState } from '@core/common/reducer';
import { LoginTypes } from '@core/store/effects/Auth';
import { createErrorMessageSelector } from '@core/store/selectors/request/errorsSelector';
import { FormWrapper } from '../../global/FormWrapper';
import { isEmail } from '@core/utils/validate';
import { createSuccessSelector } from '@core/store/selectors/request/successSelector';

const LoginFormInternal: React.FC<AllProps> = ({
    requestLogin,
    isFetching,
    requestErrors,
    requestSuccess,
    simplify,
    description,
    toggleAuth
}) => {
    const [data, setData] = React.useState<FormData>({
        email: '',
        password: '',
        remember: false
    });
    const [errors, setErrors] = React.useState<FormErrors>({});

    React.useEffect(() => {
        if (requestSuccess && simplify) {
            toggleAuth({ step: 2 });
        }
    }, [requestSuccess]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    const onToggleRemember = () => {
        setData({
            ...data,
            remember: !data.remember
        });
    };

    const validate = (): FormErrors => {
        const formErrors: FormErrors = {};

        if (!isEmail(data.email)) {
            formErrors.email = 'Email address is invalid';
        }

        if (!data.password) {
            formErrors.password = 'Password is required';
        }

        return formErrors;
    };

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const formErrors = validate();
        setErrors(formErrors);
        if (Object.keys(formErrors).length === 0) {
            requestLogin(data);
        }
    };

    const renderForm = () => {
        return (
            <form className={style.component} onSubmit={onSubmit}>
                <CircularIcon icon="lock-alt" />
                <h1 className={main.h4}>Log in to continue</h1>
                {description && <p className={style.description}>{description}</p>}
                {requestErrors && (
                    <div className={style.globalError}>
                        <GlobalError errors={requestErrors} />
                    </div>
                )}
                <FormField
                    type="email"
                    name="email"
                    placeholder="Email address"
                    value={data.email}
                    onChange={onChange}
                    error={errors.email}
                />
                <FormField
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={data.password}
                    onChange={onChange}
                    error={errors.password}
                />
                <div className={style.remember}>
                    <Checkbox
                        id="remember"
                        name="remember"
                        value={data.remember}
                        onChange={onToggleRemember}
                        text="Remember me"
                    />
                </div>
                <Button text="Sign in" loading={isFetching} type="submit" extend />
                <>
                    <div className={style.callout}>
                        <span>Don&apos;t have an account?</span>
                        {simplify ? (
                            <div className={style.signupLink} onClick={() => toggleAuth({ view: 'sign_up' })}>
                                Sign Up
                            </div>
                        ) : (
                            <Link to={Paths.auth.signup}>Sign Up</Link>
                        )}
                    </div>
                    <div className={style.forgotPassword}>
                        <Link onClick={() => toggleAuth({})} to={Paths.auth.forgotPassword}>
                            Forgot password?
                        </Link>
                    </div>
                </>
            </form>
        );
    };

    return simplify ? renderForm() : <FormWrapper>{renderForm()}</FormWrapper>;
};

const loadingSelector = createLoadingSelector([LoginTypes.BASE]);
const errorsSelector = createErrorMessageSelector([LoginTypes.BASE]);
const successSelector = createSuccessSelector([LoginTypes.BASE]);

const mapStateToProps = (state: AppState): StateProps => ({
    isFetching: loadingSelector(state),
    requestErrors: errorsSelector(state),
    requestSuccess: successSelector(state)
});

const mapDispatchToProps: DispatchProps = {
    requestLogin: Api.Auth.RequestLogin,
    toggleAuth: Api.Auth.ToggleAuth
};

const LoginForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginFormInternal));

export { LoginForm };
