import { hot } from 'react-hot-loader/root';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as React from 'react';
import { v4 as uuid } from 'uuid';
import * as Api from './store/effects';
import { connect } from 'react-redux';
import { appRoutes } from './common/routes';
import { renderRouting } from './common/routing';
import { AppState } from './common/reducer';
import { AuthModal } from './ui/molecules/global/AuthModal';
import { RequireAuth } from './store/reducers/AuthReducer/Interface';
import '@core/assets/styles/core.scss';
import { config } from '@core/common/config';
import { UserModel } from './interface/models/user';
import { gtag } from './utils/gtag';

interface StateProps {
    requireAuth?: RequireAuth;
    user?: UserModel;
}

interface DispatchProps {
    requestAnalyticsInit: (data: Api.App.AnalyticsInitPayload) => Promise<boolean>;
    requestAnalyticsHeartbeat: (data: Api.App.AnalyticsHeartbeatPayload) => void;
    requestAnalyticsRoute: (data: Api.App.AnalyticsRoutePayload) => void;
}

const AppInternal: React.FC<StateProps & DispatchProps & RouteComponentProps> = hot(
    ({ requireAuth, user, requestAnalyticsInit, requestAnalyticsHeartbeat, requestAnalyticsRoute, history }) => {
        React.useEffect(() => {
            if (config.env === 'production') {
                window.dataLayer = window.dataLayer || [];

                gtag('js', new Date());
                gtag('config', 'G-JKPSBCV1C6');
                gtag('config', 'AW-856286973');
            }

            // Click fraud detection
            const params = new URLSearchParams(window.location.search);
            const gclid = params.get('gclid');
            const device = params.get('device');
            const keyword = params.get('keyword');

            if (!window.__SESSION_ID__) {
                window.__SESSION_ID__ = uuid();
            }

            requestAnalyticsInit({
                gclid,
                sessionId: window.__SESSION_ID__,
                userId: user ? user.uid : null,
                path: window.location.pathname,
                device,
                keyword
            }).then(() => {
                // Track every 10s
                setInterval(() => {
                    if (!document.hidden) {
                        requestAnalyticsHeartbeat({
                            sessionId: window.__SESSION_ID__
                        });
                    }
                }, 10000);

                // Log page changes
                history.listen((location) => {
                    requestAnalyticsRoute({
                        sessionId: window.__SESSION_ID__,
                        path: location.pathname,
                    })
                });
            });
        }, []);
        return (
            <>
                {requireAuth && requireAuth.show && <AuthModal view={requireAuth.view} />}
                {renderRouting(appRoutes)}
            </>
        );
    }
);

const mapStateToProps = ({ auth }: AppState): StateProps => ({
    requireAuth: auth.requireAuth,
    user: auth.user
});

const mapDispatchToProps = {
    requestAnalyticsInit: Api.App.RequestAnalyticsInit,
    requestAnalyticsHeartbeat: Api.App.RequestAnalyticsHeartbeat,
    requestAnalyticsRoute: Api.App.RequestAnalyticsRoute,
};

export const App = withRouter(connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(AppInternal));
