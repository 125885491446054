import React from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import style from './Header.scss';
import { MobileMenu } from '../MobileMenu';
import { Menu } from '../Menu/Menu';
import { Logo } from '@core/ui/atoms';
import { MenuToggle } from '../MenuToggle/MenuToggle';
import global from '@core/assets/styles/global.scss';
import { Link } from 'react-router-dom';
import { Container } from '@core/ui/atoms/Container';
import { AppState } from '@core/common/reducer';
import { connect } from 'react-redux';

interface ComponentProps {
    isAbsolute?: boolean;
    theme?: 'solid';
}

interface StateProps {
    isAuthenticated: boolean;
}

const menuTransition = {
    enter: style.menuEnter,
    enterActive: style.menuEnterActive,
    exit: style.menuExit,
    exitActive: style.menuExitActive
};

const HeaderInternal: React.FC<ComponentProps & StateProps> = ({ isAbsolute, theme, isAuthenticated }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

    // Make sure the body can't scroll when menu open
    React.useEffect(() => {
        mobileMenuOpen
            ? document.body.classList.add(global.overflowHidden)
            : document.body.classList.remove(global.overflowHidden);
    }, [mobileMenuOpen]);

    return (
        <div
            className={classNames(style.header, isAbsolute && style.isAbsolute, theme === 'solid' && style.themeSolid)}>
            <Container>
                <div className={style.inner}>
                    <div className={style.logo}>
                        <Logo theme={theme} width={150} height={34} sendsHome />
                    </div>
                    <div className={style.desktopMenu}>
                        <Menu theme={theme} />
                    </div>
                    <div className={style.actions}>
                        {isAuthenticated ? <Link to="/account">Account</Link> : <Link to="/login">Log in</Link>}

                        <div className={style.buttonLink}>
                            <Link to="/pricing">Purchase Now</Link>
                        </div>
                    </div>
                    <div className={style.mobileMenu}>
                        <MenuToggle
                            solid={theme === 'solid'}
                            onToggle={() => setMobileMenuOpen(!mobileMenuOpen)}
                            active={mobileMenuOpen}
                        />
                        <CSSTransition in={mobileMenuOpen} timeout={700} unmountOnExit classNames={menuTransition}>
                            <MobileMenu />
                        </CSSTransition>
                    </div>
                </div>
            </Container>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    isAuthenticated: state.auth.isAuthenticated
});

const Header = connect(mapStateToProps)(HeaderInternal);

export { Header };
