import { Container } from '@core/ui/atoms/Container';
import React from 'react';
import style from './CallToAction.scss';
import dottedMapImage from '@core/assets/images/dotted_map.png';
import dotsSmallImage from '@core/assets/images/dots_small.png';
import { Button } from '@core/ui/atoms';

interface ComponentProps {}

const CallToAction: React.FC<ComponentProps> = () => {
    return (
        <div className={style.component}>
            <Container>
                <div className={style.inner}>
                    <div className={style.left}>
                        <h6>Get Started</h6>
                        <h2>Yes! I want barcodes for my products Instantly</h2>
                        <p>
                            In just 2 simple steps you can purchase barcodes and have them delivered within minutes from
                            now.
                        </p>
                        <div className={style.action}>
                            <Button link="/pricing" arrow text="Purchase Now" />
                            <img className={style.dots} src={dotsSmallImage} alt="Dots" />
                        </div>
                    </div>
                </div>
            </Container>
            <img className={style.dottedMap} src={dottedMapImage} />
        </div>
    );
};

export { CallToAction };
