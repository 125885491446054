export const Paths = {
    auth: {
        login: '/login',
        signup: '/signup',
        forgotPassword: '/forgot_password',
        resetPassword: '/reset_password',
        verifyEmail: '/verify/email'
    },
    main: {
        home: '/',
        about: '/about',
        contact: '/contact',
        pricing: '/pricing',
        terms: '/terms',
        privacy: '/privacy'
    },
    resources: {
        whattoknow: '/whattoknow',
        howitworks: '/howitworks'
    },
    account: {
        root: '/account',
        certificates: '/account/certificates',
        certificateDownload: '/account/certificates/download',
        payments: '/account/payments',
        personal: '/account/personal'
    },
    legal: {
        terms: '/terms',
        privacy: '/privacy'
    }
};
