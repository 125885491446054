import { Reducer } from 'redux';
import * as Api from '../../effects';
import { CheckoutReducerState } from './Interface';

const initialState: CheckoutReducerState = {
    currentStep: 1
};

type AuthActions =
    | Api.Checkout.PackageChosenAction
    | Api.Auth.ToggleAuthAction
    | Api.Payments.StripeExecutePaymentAction
    | Api.Payments.PayPalExecutePaymentAction;

const checkoutReducer: Reducer<CheckoutReducerState, AuthActions> = (
    state = initialState,
    action
): CheckoutReducerState => {
    switch (action.type) {
        // Package chosen success
        case Api.Checkout.PackageChosenTypes.PACKAGE_CHOSEN_SUCCESS:
            return {
                ...state,
                currentStep: 2,
                quantityChosen: action.payload.quantity
            };
        // Toggle auth
        case Api.Auth.ToggleAuthTypes.TOGGLE_AUTH_SUCCESS: {
            return {
                ...state,
                ...(typeof action.payload.quantity !== 'undefined' && {
                    quantityChosen: action.payload.quantity === null ? undefined : action.payload.quantity
                }),
                ...(typeof action.payload.step !== 'undefined' && {
                    currentStep: action.payload.step
                })
            };
        }
        // Stripe / PayPal payment success
        case Api.Payments.StripePaymentTypes.STRIPE_EXECUTE_PAYMENT_SUCCESS:
        case Api.Payments.PayPalExecutePaymentTypes.PAYPAL_EXECUTE_PAYMENT_SUCCESS: {
            return { ...state, currentStep: 3 };
        }

        default: {
            return state;
        }
    }
};

export { checkoutReducer };
