import { Dispatch } from 'redux';
import { AppState } from '@core/common/reducer';
import { PackageChosenTypes } from './Types';
import { PackageChosenPayload } from './Interface';

const PackageChosen = (data: PackageChosenPayload) => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
        try {
            dispatch({
                type: PackageChosenTypes.PACKAGE_CHOSEN_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch({
                type: PackageChosenTypes.PACKAGE_CHOSEN_FAILURE,
                payload: err.response.data
            });
        }
    };
};

export { PackageChosen };
