import { Reducer } from 'redux';
import * as Api from '../../effects';
import { PaymentsReducerState } from './Interface';

const initialState: PaymentsReducerState = {};

type AuthActions =
    | Api.Payments.FetchUserPaymentsAction
    | Api.Payments.StripeExecutePaymentAction
    | Api.Payments.PayPalExecutePaymentAction;

const paymentsReducer: Reducer<PaymentsReducerState, AuthActions> = (
    state = initialState,
    action
): PaymentsReducerState => {
    switch (action.type) {
        // Fetch payments success
        case Api.Payments.FetchUserPaymentsTypes.FETCH_USER_PAYMENTS_SUCCESS:
            return {
                ...state,
                fetched: true,
                list: action.payload.payments
            };
        // Stripe / PayPal payment success
        case Api.Payments.StripePaymentTypes.STRIPE_EXECUTE_PAYMENT_SUCCESS:
        case Api.Payments.PayPalExecutePaymentTypes.PAYPAL_EXECUTE_PAYMENT_SUCCESS:
            return {
                ...state,
                ...(action.payload.payment &&
                    state.fetched && {
                        list: state.list ? [...state.list, action.payload.payment] : [action.payload.payment]
                    })
            };
        default: {
            return state;
        }
    }
};

export { paymentsReducer };
