import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Api from '@core/store/effects';
import { Paths } from '@core/common/paths';
import { FormField } from '@core/ui/atoms/FormField';
import { Button } from '@core/ui/atoms/Button';
import { CircularIcon } from '@core/ui/atoms/CircularIcon';
import { GlobalError } from '@core/ui/atoms/GlobalError';
import style from './ForgotPasswordForm.scss';
import main from '@core/assets/styles/global.scss';
import { StateProps, DispatchProps, AllProps, FormData, FormErrors } from './ForgotPasswordForm.interface';
import { AppState } from '@core/common/reducer';
import { createLoadingSelector } from '@core/store/selectors/request/loadingSelector';
import { createSuccessSelector } from '@core/store/selectors/request/successSelector';
import { createErrorMessageSelector } from '@core/store/selectors/request/errorsSelector';
import { ForgotPasswordTypes } from '@core/store/effects/Auth';
import { FormWrapper } from '../../global/FormWrapper';
import { isEmail } from '@core/utils/validate';

const ForgotPasswordFormInternal: React.FC<AllProps> = ({
    requestForgotPassword,
    isFetching,
    requestSuccess,
    requestErrors
}) => {
    const [data, setData] = React.useState<FormData>({
        email: ''
    });
    const [errors, setErrors] = React.useState<FormErrors>({});

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    const validate = (): FormErrors => {
        const formErrors: FormErrors = {};

        if (!isEmail(data.email)) {
            formErrors.email = 'Email address is invalid';
        }

        return formErrors;
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const formErrors = validate();
        setErrors(formErrors);
        if (Object.keys(formErrors).length === 0) {
            requestForgotPassword(data);
        }
    };

    return (
        <FormWrapper>
            <div className={style.component}>
                {requestSuccess ? (
                    <div className={style.successView}>
                        <CircularIcon icon="check" />
                        <h1 className={main.h4}>Success</h1>
                        <p>We have sent a link to your email address to reset your password.</p>
                    </div>
                ) : (
                    <form onSubmit={onSubmit}>
                        <h1 className={main.h4}>Forgot Password</h1>
                        <p>
                            Enter the email associated with your account, and we&apos;ll email you a link to reset your
                            password.
                        </p>
                        {requestErrors && (
                            <div className={style.globalError}>
                                <GlobalError errors={requestErrors} />
                            </div>
                        )}
                        <FormField
                            type="email"
                            name="email"
                            placeholder="Email address"
                            value={data.email}
                            onChange={onChange}
                            error={errors.email}
                        />
                        <Button text="Send Reset Link" loading={isFetching} type="submit" extend />
                        <div className={style.callout}>
                            <span>Remember your password?</span>
                            <Link to={Paths.auth.login}>Log in</Link>
                        </div>
                    </form>
                )}
            </div>
        </FormWrapper>
    );
};

const loadingSelector = createLoadingSelector([ForgotPasswordTypes.BASE]);
const successSelector = createSuccessSelector([ForgotPasswordTypes.BASE]);
const errorsSelector = createErrorMessageSelector([ForgotPasswordTypes.BASE]);

const mapStateToProps = (state: AppState): StateProps => ({
    isFetching: loadingSelector(state),
    requestSuccess: successSelector(state),
    requestErrors: errorsSelector(state)
});

const mapDispatchToProps: DispatchProps = {
    requestForgotPassword: Api.Auth.RequestForgotPassword
};

const ForgotPasswordForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordFormInternal));

export { ForgotPasswordForm };
