import { AppRoute } from '@core/common/routes';
import { Login } from './Login';
import { Paths } from '@core/common/paths';

export const loginRoute: AppRoute[] = [
    {
        path: Paths.auth.login,
        exact: true,
        component: Login
    }
];
