import React from 'react';
import { connect } from 'react-redux';
import * as Api from '@core/store/effects';
import { LoginForm } from '../../forms/LoginForm';
import { Modal } from '@core/ui/atoms/Modal';
import { SignupForm } from '../../forms/SignupForm';

interface ComponentProps {
    view: 'sign_up' | 'log_in';
}

interface DispatchProps {
    toggleAuth: (data: Api.Auth.ToggleAuthPayload) => void;
}

const AuthModalInner: React.FC<ComponentProps & DispatchProps> = ({ view, toggleAuth }) => {
    return (
        <div>
            <Modal open onClose={() => toggleAuth({ quantity: null })}>
                {view === 'log_in' && (
                    <LoginForm simplify description={`Log in to continue purchasing barcodes from BarcodeBee.`} />
                )}
                {view === 'sign_up' && (
                    <SignupForm simplify description={`Sign up to continue purchasing barcodes from BarcodeBee.`} />
                )}
            </Modal>
        </div>
    );
};

const dispatchProps: DispatchProps = {
    toggleAuth: Api.Auth.ToggleAuth
};

const AuthModal = connect(null, dispatchProps)(AuthModalInner);

export { AuthModal };
