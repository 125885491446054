import React from 'react';
import * as Api from '@core/store/effects';
import { connect } from 'react-redux';
import { PrivateRoute } from '@core/ui/molecules/hocs/PrivateRoute';
import style from './Account.scss';
import { PageTemplate } from '@core/ui/templates';
import { Paths } from '@core/common/paths';
import { AppState } from '@core/common/reducer';
import { UserModel } from '@core/interface/models/user';
import { Container } from '@core/ui/atoms/Container';
import { PaymentsReducerState } from '@core/store/reducers/PaymentsReducer';
import { createLoadingSelector } from '@core/store/selectors/request/loadingSelector';
import { Loading } from '@core/ui/atoms/Loading';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { Button } from '@core/ui/atoms';
import { getDateFromTimestamp } from '@core/utils/date';
import { AccountHead } from '@core/ui/molecules/pages/dash/AccountHead';
import { AccountEmpty } from '@core/ui/molecules/pages/dash/AccountEmpty';

const JsBarcode = require('jsbarcode');

const options = {
    body: {},
    header: {
        include: true,
        theme: 'solid' as 'solid'
    },
    footer: {
        include: true
    },
    seo: {
        title: 'My Barcodes - BarcodeBee',
        meta: [
            {
                name: 'description',
                content: 'My Barcodes - BarcodeBee'
            }
        ]
    }
};

interface StateProps {
    user?: UserModel;
    payments: PaymentsReducerState;
    paymentsLoading: boolean;
}

interface DispatchProps {
    fetchUserPayments: () => void;
}

const AccountInternal: React.FC<StateProps & DispatchProps> = ({
    user,
    paymentsLoading,
    payments,
    fetchUserPayments
}) => {
    React.useEffect(() => {
        if (!payments.list) {
            fetchUserPayments();
        }
    }, []);

    React.useEffect(() => {
        if (payments.list) {
            payments.list.forEach(p => {
                if (p.barcodes) {
                    p.barcodes.forEach(b => {
                        // EAN
                        JsBarcode(`#barcode-ean-png-${b}`, b, {
                            format: 'ean13',
                            height: 200,
                            width: 4,
                            fontSize: 40,
                            background: null
                        });
                        JsBarcode(`#barcode-ean-jpg-${b}`, b, {
                            format: 'ean13',
                            height: 200,
                            width: 4,
                            fontSize: 40
                        });
                        JsBarcode(`#barcode-ean-svg-${b}`, b, {
                            format: 'ean13',
                            height: 200,
                            width: 4,
                            fontSize: 40
                        });

                        // UPC
                        JsBarcode(`#barcode-upc-png-${b}`, b.substring(1), {
                            format: 'upc',
                            height: 200,
                            width: 4,
                            fontSize: 40,
                            background: null
                        });
                        JsBarcode(`#barcode-upc-jpg-${b}`, b.substring(1), {
                            format: 'upc',
                            height: 200,
                            width: 4,
                            fontSize: 40
                        });
                        JsBarcode(`#barcode-upc-svg-${b}`, b.substring(1), {
                            format: 'upc',
                            height: 200,
                            width: 4,
                            fontSize: 40
                        });
                    });
                }
            });
        }
    }, [payments]);

    const downloadBarcodes = (barcodes: string[]) => {
        let zip = new JSZip();

        barcodes.forEach(async (b, i) => {
            const svgEan = document.getElementById(`barcode-ean-svg-${b}`);
            const pngEan = document.getElementById(`barcode-ean-png-${b}`) as HTMLImageElement;
            const jpgEan = document.getElementById(`barcode-ean-jpg-${b}`) as HTMLImageElement;
            const svgUpc = document.getElementById(`barcode-upc-svg-${b}`);
            const pngUpc = document.getElementById(`barcode-upc-png-${b}`) as HTMLImageElement;
            const jpgUpc = document.getElementById(`barcode-upc-jpg-${b}`) as HTMLImageElement;

            // Save SVG
            if (svgEan) {
                zip.file(`EAN/svg/${b}.svg`, svgEan.outerHTML);
            }

            // Save PNG
            if (pngEan) {
                const base = await fetch(pngEan.src);
                const blob = await base.blob();
                zip.file(`EAN/png/${b}.png`, blob);
            }

            // Save JPG
            if (jpgEan) {
                const base = await fetch(jpgEan.src);
                const blob = await base.blob();
                zip.file(`EAN/jpg/${b}.jpg`, blob);
            }

            // Save SVG
            if (svgUpc) {
                zip.file(`UPC/svg/${b.substring(1)}.svg`, svgUpc.outerHTML);
            }

            // Save PNG
            if (pngUpc) {
                const base = await fetch(pngUpc.src);
                const blob = await base.blob();
                zip.file(`UPC/png/${b.substring(1)}.png`, blob);
            }

            // Save JPG
            if (jpgUpc) {
                const base = await fetch(jpgUpc.src);
                const blob = await base.blob();
                zip.file(`UPC/jpg/${b.substring(1)}.jpg`, blob);
            }

            // Last item, download ZIP
            if (i === barcodes.length - 1) {
                zip.generateAsync({ type: 'blob' }).then(function(content) {
                    FileSaver.saveAs(content, 'barcodebee.zip');
                });
            }
        });
    };

    return (
        <PageTemplate options={options}>
            <div className={style.component}>
                <Container>
                    {!user || paymentsLoading ? (
                        <Loading color="PRIMARY" size={10} />
                    ) : (
                        <>
                            <AccountHead title="My Barcodes" active={Paths.account.root} />
                            {payments.list && payments.list.length ? (
                                <div className={style.listWrap}>
                                    <div className={style.listHead}>
                                        <div className={style.date}>Date</div>
                                        <div className={style.quantity}>Quantity</div>
                                    </div>
                                    <div className={style.list}>
                                        {payments.list
                                            .sort((a, b) => b.created - a.created)
                                            .map(p => {
                                                const { barcodes } = p;
                                                return !barcodes ? null : (
                                                    <div key={p.paymentId} className={style.item}>
                                                        <div className={style.left}>
                                                            <div className={style.date}>
                                                                {getDateFromTimestamp(p.created)}
                                                            </div>
                                                            <div
                                                                className={
                                                                    style.quantity
                                                                }>{`${barcodes.length} Barcodes`}</div>
                                                        </div>
                                                        <Button
                                                            text={`Download Barcodes`}
                                                            onClick={() => downloadBarcodes(barcodes)}
                                                        />
                                                        {barcodes.map(b => {
                                                            return (
                                                                <div key={b} style={{ display: 'none' }}>
                                                                    <img id={`barcode-ean-png-${b}`} />
                                                                    <img id={`barcode-ean-jpg-${b}`} />
                                                                    <svg id={`barcode-ean-svg-${b}`} />
                                                                    <img id={`barcode-upc-png-${b}`} />
                                                                    <img id={`barcode-upc-jpg-${b}`} />
                                                                    <svg id={`barcode-upc-svg-${b}`} />
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            ) : (
                                <AccountEmpty text="You have not yet purchased any barcodes" />
                            )}
                        </>
                    )}
                </Container>
            </div>
        </PageTemplate>
    );
};

const loadingSelector = createLoadingSelector([Api.Payments.FetchUserPaymentsTypes.BASE]);

const mapStateToProps = (state: AppState): StateProps => {
    return {
        user: state.auth.user,
        payments: state.payments,
        paymentsLoading: loadingSelector(state)
    };
};

const mapDispatchToProps: DispatchProps = {
    fetchUserPayments: Api.Payments.FetchUserPayments
};

const enhance = connect(mapStateToProps, mapDispatchToProps)(AccountInternal);

export const Account = PrivateRoute(enhance);
