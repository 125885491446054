import * as React from 'react';
import { connect } from 'react-redux';
import classes from 'classnames';
import styles from './Logo.scss';
import { Link } from 'react-router-dom';
import { Paths } from '@core/common/paths';
import { AppState } from '@core/common/reducer';

interface ComponentProps {
    sendsHome?: boolean;
    height?: number;
    width?: number;
    theme?: 'solid';
}

interface StateProps {
    locale: string;
}

const LogoInternal: React.FC<ComponentProps & StateProps> = ({ sendsHome, height, width, theme, locale }) => {
    const LogoElement = (
        <svg height="30" width="30" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="m256 438.961c-8.284 0-15 6.716-15 15v43.039c0 8.284 6.716 15 15 15s15-6.716 15-15v-43.039c0-8.284-6.716-15-15-15z"
                    fill="#eae0db"
                />
                <path d="m271 497v-43.039c0-8.284-6.716-15-15-15v73.039c8.284 0 15-6.715 15-15z" fill="#dbd1cc" />
                <path
                    d="m509.527 311.471c-7.749-35.534-32.726-64.314-66.814-76.989l-168.081-62.496h-37.263l-168.081 62.496c-34.088 12.675-59.065 41.455-66.814 76.989-7.749 35.533 2.978 72.101 28.695 97.816 20.213 20.214 47.126 31.165 74.976 31.165 7.582 0 15.237-.812 22.84-2.471 35.534-7.748 64.314-32.726 76.989-66.813l50.026-134.544 50.026 134.544c12.675 34.088 41.456 59.065 76.989 66.813 7.606 1.659 15.256 2.471 22.84 2.471 27.847 0 54.765-10.953 74.976-31.165 25.718-25.715 36.445-62.283 28.696-97.816z"
                    fill="#f8e8e8"
                />
                <path
                    d="m442.713 234.482-168.081-62.496h-18.632v64.639l50.026 134.544c12.675 34.088 41.456 59.065 76.989 66.813 7.606 1.659 15.256 2.471 22.84 2.471 27.847 0 54.765-10.953 74.976-31.165 25.717-25.716 36.443-62.283 28.695-97.816-7.748-35.535-32.725-64.315-66.813-76.99z"
                    fill="#eae0db"
                />
                <path
                    d="m322.003 352.735c-2.806-4.014-7.396-6.404-12.293-6.404h-107.42c-4.897 0-9.487 2.391-12.293 6.404-2.807 4.014-3.477 9.146-1.795 13.745 20.857 57.063 55.162 95.851 56.612 97.474 2.846 3.186 6.915 5.007 11.186 5.007s8.34-1.821 11.186-5.007c1.45-1.623 35.755-40.41 56.612-97.474 1.682-4.6 1.012-9.732-1.795-13.745z"
                    fill="#404a4a"
                />
                <g>
                    <g>
                        <g>
                            <path
                                d="m286.125 97.924c-8.284 0-15-6.716-15-15v-1.934c0-27.134 10.567-52.645 29.753-71.832l4.765-4.765c5.857-5.857 15.355-5.857 21.213 0s5.858 15.355 0 21.213l-4.765 4.765c-13.521 13.521-20.967 31.498-20.967 50.619v1.934c.001 8.284-6.715 15-14.999 15z"
                                fill="#323c3c"
                            />
                        </g>
                    </g>
                </g>
                <path
                    d="m309.71 346.33h-53.71v122.63c4.271 0 8.34-1.821 11.186-5.007 1.45-1.623 35.755-40.41 56.612-97.474 1.681-4.6 1.011-9.731-1.795-13.745-2.806-4.013-7.395-6.404-12.293-6.404z"
                    fill="#323c3c"
                />
                <path
                    d="m324.42 267.911h-136.84c-8.284 0-15 6.716-15 15 0 27.106 5.256 55.224 15.622 83.571h135.596c10.366-28.348 15.622-56.465 15.622-83.571 0-8.285-6.716-15-15-15z"
                    fill="#efc277"
                />
                <path
                    d="m324.42 267.911h-68.42v98.571h67.798c10.366-28.348 15.622-56.465 15.622-83.571 0-8.285-6.716-15-15-15z"
                    fill="#e9a43a"
                />
                <path
                    d="m267.187 101.857c-2.846-3.186-6.915-5.007-11.187-5.007-4.271 0-8.341 1.821-11.187 5.007-2.949 3.302-72.233 81.998-72.233 181.054h166.84c0-99.056-69.284-177.752-72.233-181.054z"
                    fill="#404a4a"
                />
                <path
                    d="m339.42 282.911c0-99.056-69.284-177.752-72.233-181.054-2.846-3.186-6.915-5.007-11.187-5.007v186.061z"
                    fill="#323c3c"
                />
                <g>
                    <g>
                        <path
                            d="m225.875 97.919c-8.284 0-15-6.711-15-14.995v-.01c0-22.022-9.907-42.488-27.18-56.148-6.498-5.14-7.6-14.572-2.46-21.07s14.574-7.599 21.07-2.461c24.512 19.387 38.57 48.429 38.57 79.68 0 8.284-6.716 15.004-15 15.004z"
                            fill="#404a4a"
                        />
                    </g>
                </g>
                <circle cx="256" cy="147.455" fill="#efc277" r="86.205" />
                <path
                    d="m342.205 147.454c0-47.533-38.671-86.204-86.205-86.204v172.41c47.533 0 86.205-38.672 86.205-86.206z"
                    fill="#e9a43a"
                />
            </g>
        </svg>
    );

    return sendsHome ? (
        <Link to={Paths.main.home} className={classes(styles.link, theme === 'solid' && styles.themeSolid)}>
            {LogoElement}
            <div className={styles.text}>
                barcodebee<span>AU</span>
            </div>
        </Link>
    ) : (
        LogoElement
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    locale: state.locale.locale
});

const Logo = connect(mapStateToProps)(LogoInternal);

export { Logo };
