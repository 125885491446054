import React from 'react';
import classNames from 'classnames';
import style from './SectionDrop.scss';

interface ComponentProps {
    title: string;
    description: string;
    actionText: string;
}

const SectionDrop: React.FC<ComponentProps> = ({ title, description, actionText, children }) => {
    const [displayContent, setDisplayContent] = React.useState(false);

    return (
        <div className={style.component}>
            <div className={classNames(style.head, displayContent && style.spacer)}>
                <div className={style.title}>{title}</div>
                <button onClick={() => setDisplayContent(!displayContent)} className={style.action}>
                    {displayContent ? 'Cancel' : actionText}
                </button>
            </div>
            {displayContent ? children : <div className={style.description}>{description}</div>}
        </div>
    );
};

export { SectionDrop };
