import { RequestApi } from './request';
import { Request } from 'express';

export interface AppApi {
    requestApi: RequestApi;
}

export const initializeApi = (apiUrl: string, request?: Request): AppApi => ({
    requestApi: new RequestApi(apiUrl, request)
});
