import { AppRoute } from '@core/common/routes';
import { VerifyEmail } from './VerifyEmail';
import { Paths } from '@core/common/paths';

export const verifyEmailRoute: AppRoute[] = [
    {
        path: Paths.auth.verifyEmail,
        exact: true,
        component: VerifyEmail
    }
];
