import React from 'react';
import { connect } from 'react-redux';
import * as Api from '@core/store/effects';
import { Icon } from '@core/ui/atoms/Icon';
import { Loading } from '@core/ui/atoms/Loading';
import style from './EmailConfirmed.scss';
import { Props, StateProps, DispatchProps } from './EmailConfirmed.interface';
import { AppState } from '@core/common/reducer';
import { createLoadingSelector } from '@core/store/selectors/request/loadingSelector';
import { createErrorMessageSelector } from '@core/store/selectors/request/errorsSelector';
import { SendConfirmationEmailTypes } from '@core/store/effects/User';
import { createSuccessSelector } from '@core/store/selectors/request/successSelector';

const EmailConfirmedInternal: React.FC<Props> = ({
    auth,
    requestEmailConfirmation,
    isFetching,
    isSuccess,
    requestErrors
}) => {
    const { user } = auth;

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        requestEmailConfirmation();
    };

    return !user ? null : (
        <div className={style.emailConfirmed}>
            <p>
                {user.emailVerified ? (
                    <span>You have confirmed your email: </span>
                ) : (
                    <span>You have not confirmed your email: </span>
                )}
                <b>{user.email}</b>
                <span>. A confirmed email is important to allow us to securely communicate with you.</span>
            </p>
            {isFetching && (
                <div className={style.loading}>
                    <Loading color="PRIMARY" size={10} />
                </div>
            )}
            {!isFetching && isSuccess && (
                <div className={style.emailSent}>
                    <Icon name="check-circle" />
                    <span>
                        A confirmation email has been sent to <b>{user.email}</b>
                    </span>
                </div>
            )}
            {!isFetching && !isSuccess && !user.emailVerified && (
                <button type="button" className={style.emailLink} onClick={onSubmit}>
                    Send confirmation email
                </button>
            )}
        </div>
    );
};

const successSelector = createSuccessSelector([SendConfirmationEmailTypes.BASE]);
const loadingSelector = createLoadingSelector([SendConfirmationEmailTypes.BASE]);
const errorsSelector = createErrorMessageSelector([SendConfirmationEmailTypes.BASE]);

const mapStateToProps = (state: AppState): StateProps => ({
    auth: state.auth,
    isSuccess: successSelector(state),
    isFetching: loadingSelector(state),
    requestErrors: errorsSelector(state)
});

const mapDispatchToProps: DispatchProps = {
    requestEmailConfirmation: Api.User.SendConfirmationEmail
};

const EmailConfirmed = connect(mapStateToProps, mapDispatchToProps)(EmailConfirmedInternal);

export { EmailConfirmed };
