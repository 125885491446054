/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Paths } from '@core/common/paths';
import { AuthReducerState } from '@core/store/reducers/AuthReducer';

interface Props {
    auth: AuthReducerState;
}

const PrivateRoute = <P extends object>(ChildComponent: React.FC<P> | ComponentType<P>) => {
    const PrivateRouteInternal: React.FC<P & Props> = ({ auth }, props) => {
        return auth.isAuthenticated ? <ChildComponent {...props} /> : <Redirect to={Paths.auth.login} />;
    };

    const mapStateToProps = ({ auth }: Props) => {
        return { auth };
    };

    return connect(mapStateToProps)(PrivateRouteInternal as React.FC<Anything>);
};

export { PrivateRoute };
