import React from 'react';
import * as Api from '@core/store/effects';
import { connect } from 'react-redux';
import style from './Package.scss';
import { AppState } from '@core/common/reducer';

interface ComponentProps {
    quantity: number;
    priceInCents: number;
}

interface DispatchProps {
    toggleAuth: (data: Api.Auth.ToggleAuthPayload) => void;
    packageChosen: (data: Api.Checkout.PackageChosenPayload) => void;
}

interface StateProps {
    isAuthenticated: boolean;
}

type AllProps = ComponentProps & StateProps & DispatchProps;

const PackageInternal: React.FC<AllProps> = ({
    quantity,
    priceInCents,
    packageChosen,
    isAuthenticated,
    toggleAuth
}) => {
    const handleChosen = () => {
        if (isAuthenticated) {
            packageChosen({ quantity });
        } else {
            toggleAuth({ view: 'sign_up', quantity });
        }
    };

    return (
        <div className={style.package} onClick={handleChosen}>
            <div className={style.left}>
                <div className={style.quantity}>{quantity === 1 ? '1 Barcode' : `${quantity} Barcodes`}</div>
                <div className={style.price}>
                    <div className={style.dollar}>$</div>
                    <div className={style.number}>{(priceInCents / 100).toFixed(2)}</div>
                </div>
            </div>
            <div className={style.buttonWrap}>
                <button>Select</button>
            </div>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    };
};

const mapDispatchToProps: DispatchProps = {
    packageChosen: Api.Checkout.PackageChosen,
    toggleAuth: Api.Auth.ToggleAuth
};

const Package = connect(mapStateToProps, mapDispatchToProps)(PackageInternal);

export { Package };
