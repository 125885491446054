import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Api from '@core/store/effects';
import { useScript } from '@core/hooks/useScript';
import { AppState } from '@core/common/reducer';
import { createLoadingSelector } from '@core/store/selectors/request/loadingSelector';
import { createErrorMessageSelector } from '@core/store/selectors/request/errorsSelector';
import { PayPalCreatePaymentTypes } from '@core/store/effects/Payments/PayPalCreatePayment';
import { PayPalExecutePaymentTypes } from '@core/store/effects/Payments/PayPalExecutePayment';
import { StateProps, DispatchProps, AllProps } from './PayPalButton.interface';
import { config } from '@core/common/config';
import style from './PayPalButton.scss';
import { Loading } from '@core/ui/atoms/Loading';
import { mapBarcodeQuantityToPrice } from '@core/utils/helpers/mapBarcodeQuantityToPrice';
import { Modal } from '@core/ui/atoms/Modal';

const PayPalButtonInternal: React.FC<AllProps> = ({
    createPayPalPayment,
    executePayPalPayment,
    history,
    quantityChosen
}) => {
    if (!quantityChosen) {
        return null;
    }

    const priceInCents = mapBarcodeQuantityToPrice(quantityChosen);

    if (!priceInCents) {
        return null;
    }

    // Load the PayPal SDK
    const [loaded, error] = useScript(
        `https://www.paypal.com/sdk/js?client-id=${config.paypalPublicKey}&currency=AUD&disable-funding=card`
    );

    // Keep track if the PayPal button has already mounted
    const [rendered, setRendered] = React.useState(false);

    // If the payment is in progress
    const [inProgress, setInProgress] = React.useState(false);

    // If PayPal returns an error
    const [errorExists, setErrorExists] = React.useState(false);

    // The node to render the button to
    let paypalRef = React.useRef(null);

    React.useEffect(() => {
        if (window.paypal && !rendered && paypalRef.current) {
            window.paypal
                .Buttons({
                    style: {
                        layout: 'vertical',
                        color: 'white',
                        shape: 'rect',
                        label: 'paypal',
                        height: 45,
                        tagline: false
                    },
                    onInit: () => {
                        setRendered(true);
                    },
                    onClick: () => {
                        setErrorExists(false);
                    },
                    onCancel: () => {
                        setInProgress(false);
                    },
                    createOrder: async () => {
                        setInProgress(true);

                        // Reset error state
                        setErrorExists(false);

                        // Create the payment & return the orderID
                        return createPayPalPayment({ amount: priceInCents / 100, quantity: quantityChosen });
                    },
                    onApprove: async (data: { orderID: string }) => {
                        // Execute the payment & redirect on success
                        await executePayPalPayment({ orderID: data.orderID, quantity: quantityChosen });
                    },
                    onError: () => {
                        setInProgress(false);

                        // Show the error message
                        setErrorExists(true);
                    }
                })
                .render(paypalRef.current);
        }
    }, [loaded]);

    return loaded && !error ? (
        <>
            <div className={style.wrapper}>
                <div className={style.paypalMountWrap}>
                    {inProgress && (
                        <div className={style.loader}>
                            <Loading color="SECONDARY" size={6} />
                        </div>
                    )}
                    <div ref={paypalRef} className={style.paypalMount}>
                        {/* PayPal button is mounted here */}
                    </div>
                </div>
                {errorExists && (
                    <div className={style.error}>
                        Something went wrong, please try again or try another payment method.
                    </div>
                )}
            </div>
            <Modal open={inProgress}>
                <div className={style.loading}>
                    <h6>Processing, please wait...</h6>
                    <Loading color="SECONDARY" size={12} />
                </div>
            </Modal>
        </>
    ) : null;
};

const loadingSelector = createLoadingSelector([PayPalCreatePaymentTypes.BASE, PayPalExecutePaymentTypes.BASE]);
const errorsSelector = createErrorMessageSelector([PayPalCreatePaymentTypes.BASE, PayPalExecutePaymentTypes.BASE]);

const mapStateToProps = (state: AppState): StateProps => ({
    isFetching: loadingSelector(state),
    requestErrors: errorsSelector(state),
    quantityChosen: state.checkout.quantityChosen
});

const mapDispatchToProps: DispatchProps = {
    createPayPalPayment: Api.Payments.PayPalCreatePayment,
    executePayPalPayment: Api.Payments.PayPalExecutePayment
};

export const PayPalButton = withRouter(connect(mapStateToProps, mapDispatchToProps)(PayPalButtonInternal));
