import { Dispatch } from 'redux';
import { AppApi } from '@core/api';
import { AppState } from '@core/common/reducer';
import { ResetPasswordTypes } from './Types';
import { ResetPasswordPayload, ResetPasswordResponse } from './Interface';

const RequestResetPassword = (data: ResetPasswordPayload) => {
    return async (dispatch: Dispatch, getState: () => AppState, { requestApi }: AppApi) => {
        try {
            dispatch({
                type: ResetPasswordTypes.RESET_PASSWORD_REQUEST
            });
            const res = await requestApi.post<ResetPasswordResponse>('/auth/reset_password', data);
            dispatch({
                type: ResetPasswordTypes.RESET_PASSWORD_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: ResetPasswordTypes.RESET_PASSWORD_FAILURE,
                payload: err.response.data
            });
        }
    };
};

export { RequestResetPassword };
