import { AuthReducerState } from '@core/store/reducers/AuthReducer';
import { FetchCurrentUserResponse } from '@core/store/effects/User';

export const fetchCurrentUserSuccess = (
    state: AuthReducerState,
    payload: FetchCurrentUserResponse
): AuthReducerState => {
    const { success, user } = payload;
    return {
        ...state,
        isAuthenticated: success,
        user
    };
};
