import { AppState } from '@core/common/reducer';
import { ApiErrors } from '@core/interface/errors';

export const createErrorMessageSelector = (actions: string[]) => (state: AppState): ApiErrors => {
    const errors = actions.map(action => state.errors[action]);
    if (errors && errors[0]) {
        return errors[0];
    }
    return {};
};
