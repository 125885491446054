import React from 'react';
import classes from 'classnames';
import { connect } from 'react-redux';
import { FetchCurrentUser } from '@core/store/effects/User';
import style from './Home.scss';
import { PageTemplate } from '@core/ui/templates';
import { Container } from '@core/ui/atoms/Container';
import heroImage from '@core/assets/images/hero.png';
import heroPreviewImage from '@core/assets/images/hero_preview.png';
import dotsImage from '@core/assets/images/dots.png';
import dotsSmallImage from '@core/assets/images/dots_small.png';
import dotsLargeImage from '@core/assets/images/dots_large.png';
import dotsVerticalImage from '@core/assets/images/dots_vertical.png';
import ovalRightImage from '@core/assets/images/oval_right.png';
import ovalLeftImage from '@core/assets/images/oval_left.png';
import pointsFeaturedImage from '@core/assets/images/points_featured.jpg';
import aboutPrimaryImage from '@core/assets/images/about_primary.jpg';
import aboutSecondaryImage from '@core/assets/images/about_secondary.jpg';
import waveBackgroundImage from '@core/assets/images/wave_background.png';
import feedbackImage from '@core/assets/images/feedback.png';
import { Button } from '@core/ui/atoms';
import { Icon } from '@core/ui/atoms/Icon';
import { Paths } from '@core/common/paths';
import { CallToAction } from '@core/ui/molecules/global/CallToAction';
import { AppState } from '@core/common/reducer';

interface DispatchProps {
    fetchCurrentUser: () => void;
}

interface StateProps {
    locale: string;
}

const options = {
    body: {},
    header: {
        include: true,
        clean: false
    },
    footer: {
        include: true
    },
    seo: {
        title: 'Barcodes for Your Products - BarcodeBee',
        meta: [
            {
                name: 'description',
                content: 'Barcodes for Your Products - BarcodeBee'
            }
        ]
    }
};

const HomeInternal: React.FC<StateProps & DispatchProps> = ({ fetchCurrentUser, locale }) => {
    return (
        <PageTemplate options={options}>
            <div className={style.homePage}>
                <div className={style.hero} style={{ background: `url(${heroImage})` }}>
                    <div className={style.content}>
                        <Container>
                            <div className={style.inner}>
                                <div className={style.left}>
                                    {locale === 'au' ? (
                                        <h1>Need barcodes to sell your products in Australia?</h1>
                                    ) : (
                                        <h1>Need barcodes to sell your products in Australia?</h1>
                                    )}

                                    <p>
                                        We help Australian businesses get barcodes for their products. It's as easy as
                                        selecting your barcode package, getting instant delivery, and then applying them
                                        to your products.
                                    </p>
                                    <div className={style.actions}>
                                        <Button theme="solid-light" text="Purchase Now" link="/pricing" arrow />
                                    </div>
                                </div>
                                <div className={style.right}>
                                    <img className={style.preview} src={heroPreviewImage} alt="Product Preview" />
                                    <img className={style.dots} src={dotsImage} alt="Dots" />
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
                <div className={style.featureSection}>
                    <Container>
                        <div className={style.inner}>
                            <div className={style.left}>
                                <h6>Reduced Complexity</h6>
                                <h3>Making it easy to get barcodes for your products</h3>
                                <p>
                                    We help Australian business by taking the complexity out of getting authentic and
                                    reliable barcodes for your products.
                                </p>
                                <div className={style.action}>
                                    <Button link={Paths.resources.whattoknow} arrow text="Read The Faq" />
                                    <img className={style.dots} src={dotsSmallImage} alt="Dots" />
                                </div>
                            </div>

                            <div className={style.right}>
                                <div className={style.cardRow}>
                                    <div className={style.card}>
                                        <div className={classes(style.icon, style.red)}>
                                            <Icon size="25px" color="#ff4959" name="globe-asia" />
                                        </div>

                                        <h5>Accepted Globally</h5>
                                        <p>Our barcodes are accepted globally and by major Australian retailers.</p>
                                    </div>
                                    <div className={style.card}>
                                        <div className={classes(style.icon, style.green)}>
                                            <Icon size="28px" color="#30d97a" name="lightbulb-on" />
                                        </div>
                                        <h5>Zero Ongoing Fees</h5>
                                        <p>
                                            Pay for your barcodes once and own them for life. There are no hidden
                                            charges or fees.
                                        </p>
                                    </div>
                                </div>
                                <div className={style.cardRow}>
                                    <div className={style.card}>
                                        <div className={classes(style.icon, style.blue)}>
                                            <Icon size="23px" color="#1e91fd" name="file-certificate" />
                                        </div>

                                        <h5>Ownership Certificate</h5>
                                        <p>
                                            Recieve a Certificate of Authenticity & Ownership for every order placed
                                            with us.
                                        </p>
                                    </div>
                                    <div className={style.card}>
                                        <div className={classes(style.icon, style.orange)}>
                                            <Icon size="30px" color="#f7a57c" name="rabbit-fast" />
                                        </div>
                                        <h5>Instant Delivery</h5>
                                        <p>
                                            Download your barcode package from your BarcodeBee account in just a few
                                            minutes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                    <img className={style.oval} src={ovalRightImage} alt="Oval" />
                </div>
                <div className={style.pointsSection}>
                    <img className={style.oval} src={ovalLeftImage} alt="Oval" />
                    <Container>
                        <div className={style.inner}>
                            <div className={style.left}>
                                <div className={style.featured}>
                                    <div className={style.image}>
                                        <img src={pointsFeaturedImage} alt="Solution" />
                                    </div>
                                    <div className={style.content}>
                                        <h5>Begin Selling Your Products in Stores with Our Barcodes</h5>
                                        <Button link={Paths.resources.howitworks} text="Read More" arrow />
                                    </div>
                                </div>
                                <img className={style.dots} src={dotsLargeImage} alt="Dots" />
                            </div>

                            <div className={style.right}>
                                <h6>Key Points</h6>
                                <h3>Accepted by retailers throughout Australia</h3>
                                <p>
                                    Our barcodes are not just accepted by major retailers throughout Australia, but can
                                    also be used globally in any country.
                                </p>
                                <div className={style.points}>
                                    <div className={style.point}>
                                        <Icon name="angle-right" size="18px" color="#3d3d3d" />
                                        <div className={style.title}>Authentic Barcodes</div>
                                    </div>
                                    <div className={style.point}>
                                        <Icon name="angle-right" size="18px" color="#3d3d3d" />
                                        <div className={style.title}>UCC/GS1 Originated</div>
                                    </div>
                                    <div className={style.point}>
                                        <Icon name="angle-right" size="18px" color="#3d3d3d" />
                                        <div className={style.title}>Certification Provided</div>
                                    </div>
                                    <div className={style.point}>
                                        <Icon name="angle-right" size="18px" color="#3d3d3d" />
                                        <div className={style.title}>Zero Hidden Fees</div>
                                    </div>
                                    <div className={style.point}>
                                        <Icon name="angle-right" size="18px" color="#3d3d3d" />
                                        <div className={style.title}>Fast & Easy Delivery</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <div className={style.aboutSection}>
                    <Container>
                        <div className={style.inner}>
                            <div className={style.left}>
                                <h6>About Us</h6>
                                <h3>Helping you to Start Selling Your Products Now</h3>
                                <p>
                                    We're dedicated to making things easy for you. That's why we'll handle providing you
                                    legitimate barcodes while you can focus on your business.
                                </p>
                                <div className={style.action}>
                                    <Button link={Paths.main.about} arrow text="More About Us" />
                                    <img className={style.dots} src={dotsSmallImage} alt="Dots" />
                                </div>
                            </div>

                            <div className={style.right}>
                                <div className={style.primary}>
                                    <div className={style.play}>
                                        <Icon color="#fff" size="25px" name="check-double" />
                                    </div>
                                    <img className={style.imagePrimary} src={aboutPrimaryImage} alt="Solution" />
                                </div>
                                <img className={style.imageSecondary} src={aboutSecondaryImage} alt="Solution" />
                                <img className={style.dots} src={dotsVerticalImage} alt="Dots" />
                            </div>
                        </div>
                    </Container>
                    <img className={style.oval} src={ovalRightImage} alt="Oval" />
                </div>
                <div className={style.whySection} style={{ background: `url(${waveBackgroundImage})` }}>
                    <Container>
                        <div className={style.whyInner}>
                            <div className={style.head}>
                                <h6>Why Choose Us?</h6>
                                <h3>BarcodeBee is the Easiest Solution to Owning Your Barcodes</h3>
                            </div>
                            <div className={style.boxes}>
                                <div className={style.box}>
                                    <div className={style.number}>01</div>
                                    <div className={style.left}>
                                        <Icon size="36px" name="barcode-read" color="#ff4959" />
                                    </div>
                                    <div className={style.right}>
                                        <h5>Authentic Barcodes</h5>
                                        <p>
                                            The barcodes we provide are authentic, legal, and were{' '}
                                            <b>originally issued from UCC/GS1</b>. You will also be provided with a
                                            Certificate of Ownership.
                                        </p>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={style.number}>02</div>
                                    <div className={style.left}>
                                        <Icon size="36px" name="chart-line" color="#ff4959" />
                                    </div>
                                    <div className={style.right}>
                                        <h5>Efficient & Reliable</h5>
                                        <p>
                                            Our technical infrastructure provides a seamless, efficient & reliable
                                            experience from placing your order to recieving your barcodes within
                                            minutes.
                                        </p>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={style.number}>03</div>
                                    <div className={style.left}>
                                        <Icon size="40px" name="users" color="#ff4959" />
                                    </div>
                                    <div className={style.right}>
                                        <h5>Here to Support You</h5>
                                        <p>
                                            We're here to help you. For us that means providing a phenonemal service to
                                            get you selling your products today, not tomorrow.
                                        </p>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={style.number}>04</div>
                                    <div className={style.left}>
                                        <Icon size="40px" name="chart-network" color="#ff4959" />
                                    </div>
                                    <div className={style.right}>
                                        <h5>Worldwide Coverage</h5>
                                        <p>
                                            Our barcodes are ready for use globally. So no matter whether you're selling
                                            within Australia or abroad you're covered.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <div className={style.customerSection}>
                    <Container extend>
                        <div className={style.customerInner}>
                            <div className={style.head}>
                                <h6>Our Customers</h6>
                                <h3>What our Customers are saying about BarcodeBee</h3>
                            </div>
                            <div className={style.boxes}>
                                <div className={style.box}>
                                    <div className={style.image}>
                                        <img src={feedbackImage} alt="Feedback" />
                                    </div>
                                    <p>
                                        Getting our barcodes from BarcodeBee has been our easiest experience to date. It
                                        was really easy to download our barcodes and get them onto our products, which
                                        now scan through perfectly in multiple stores.
                                    </p>
                                    <div className={style.bottom}>
                                        <div className={style.name}>Tahlia Nguyen</div>
                                        <div className={style.company}>Company Director</div>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={style.image}>
                                        <img src={feedbackImage} alt="Feedback" />
                                    </div>
                                    <p>
                                        I was very happy with ordering from BarcodeBee. Ordering was fast, got the job
                                        done, and is exactly what we needed. Will be looking to no other place than here
                                        in the future for purchasing our barcodes.
                                    </p>
                                    <div className={style.bottom}>
                                        <div className={style.name}>Tristan Mansader</div>
                                        <div className={style.company}>Company Director</div>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={style.image}>
                                        <img src={feedbackImage} alt="Feedback" />
                                    </div>
                                    <p>
                                        From not knowing anything about how barcodes work, I'm happy to say that
                                        purchasing from BarcodeBee has been a "painless" process. All the barcodes have
                                        worked perfectly and I will be ordering more again in the future.
                                    </p>
                                    <div className={style.bottom}>
                                        <div className={style.name}>Alex Regras</div>
                                        <div className={style.company}>Product Manager</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <CallToAction />
            </div>
        </PageTemplate>
    );
};

const mapDispatchToProps = {
    fetchCurrentUser: FetchCurrentUser
};

const mapStateToProps = (state: AppState): StateProps => ({
    locale: state.locale.locale
});

const enhance = connect(mapStateToProps, mapDispatchToProps);

export const Home = enhance(HomeInternal);
