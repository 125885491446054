import React from 'react';
import { Modal } from '@core/ui/atoms/Modal';
import { FormField } from '@core/ui/atoms/FormField';
import { Button } from '@core/ui/atoms';
import style from './ConfirmPasswordModal.scss';

interface FormData {
    password: string;
}

type FormErrors = Partial<FormData>;

interface ComponentProps {
    onSubmit: (password: string) => void;
}

const ConfirmPasswordModal: React.FC<ComponentProps> = ({ onSubmit }) => {
    const [data, setData] = React.useState<FormData>({
        password: ''
    });
    const [errors, setErrors] = React.useState<FormErrors>({});

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    const validate = (): FormErrors => {
        const formErrors: FormErrors = {};

        if (!data.password) {
            formErrors.password = 'Password is required';
        }

        return formErrors;
    };

    const onSubmission = (e: React.FormEvent) => {
        e.preventDefault();
        const formErrors = validate();
        setErrors(formErrors);

        if (Object.keys(formErrors).length === 0) {
            onSubmit(data.password);
        }
    };

    return (
        <div className={style.component}>
            <Modal open>
                <h4>Confirm your password</h4>
                <FormField
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={data.password}
                    onChange={onChange}
                    error={errors.password}
                />
                <Button text="Submit" onClick={onSubmission} />
            </Modal>
        </div>
    );
};

export { ConfirmPasswordModal };
