import React from 'react';
import classes from 'classnames';
import style from './Container.scss';

interface ComponentProps {
    extend?: boolean;
}

const Container: React.FC<ComponentProps> = ({ children, extend }) => (
    <div className={classes(style.component, extend && style.extend)}>{children}</div>
);

export { Container };
