import React from 'react';
import { Link } from 'react-router-dom';
import style from './Footer.scss';
import { Paths } from '@core/common/paths';
import { config } from '@core/common/config';
import { Container } from '@core/ui/atoms/Container';
import footerImage from '@core/assets/images/footer.png';
import { Logo } from '@core/ui/atoms';

const Footer: React.FC = () => {
    return (
        <div className={style.footer} style={{ background: `url(${footerImage})` }}>
            <Container>
                <div className={style.footerInner}>
                    <div className={style.left}>
                        <div className={style.col}>
                            <div className={style.title}>{config.siteName}</div>
                            <ul>
                                <Link to={Paths.main.home}>
                                    <li>Overview</li>
                                </Link>
                                <Link to={Paths.resources.howitworks}>
                                    <li>How it Works</li>
                                </Link>
                                <Link to={Paths.resources.whattoknow}>
                                    <li>What to Know</li>
                                </Link>
                            </ul>
                        </div>
                        <div className={style.col}>
                            <div className={style.title}>Account</div>
                            <ul>
                                <Link to={Paths.auth.login}>
                                    <li>Log In</li>
                                </Link>
                                <Link to={Paths.auth.signup}>
                                    <li>Sign Up</li>
                                </Link>
                                <Link to={Paths.account.root}>
                                    <li>My Account</li>
                                </Link>
                            </ul>
                        </div>
                        <div className={style.col}>
                            <div className={style.title}>Company</div>
                            <ul>
                                <Link to={Paths.main.about}>
                                    <li>About Us</li>
                                </Link>
                                <Link to={Paths.main.pricing}>
                                    <li>Order Now</li>
                                </Link>
                                <Link to={Paths.main.contact}>
                                    <li>Contact</li>
                                </Link>
                            </ul>
                        </div>
                    </div>
                    <div className={style.right}>
                        <Logo sendsHome />
                        <p>
                            Keeping things simple for you. We're dedicated towards helping businesses get barcodes for
                            their products.
                        </p>
                    </div>
                </div>
                <div className={style.copyright}>
                    <div className={style.text}>{`© 2020 ${config.siteName}. All rights reserved`}</div>
                    <div className={style.links}>
                        <Link to={Paths.main.contact}>Help</Link>
                        <Link to={Paths.legal.terms}>Terms</Link>
                        <Link to={Paths.legal.privacy}>Privacy</Link>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export { Footer };
