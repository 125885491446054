/* eslint-disable react/button-has-type */
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Loading } from '@core/ui/atoms/Loading';
import style from './Button.scss';
import { Icon } from '../Icon';

interface Props {
    arrow?: boolean;
    text: string;
    type?: 'button' | 'submit';
    loading?: boolean;
    link?: string;
    extend?: boolean;
    enlarge?: boolean;
    theme?: 'solid-light' | 'outline-light';
    onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
    openInNewTab?: boolean;
    className?: string;
}

const Button: React.FC<Props> = ({
    arrow,
    text,
    type = 'button',
    loading = false,
    link,
    extend,
    theme,
    enlarge,
    onClick,
    openInNewTab,
    className,
    children
}) => {
    const [width, setWidth] = React.useState<number | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const buttonRef = React.useRef<HTMLButtonElement | null>(null);

    React.useEffect(() => {
        const node = buttonRef.current;

        // Set width on button if loading (stop shrinking)
        setWidth(loading && node ? node.getBoundingClientRect().width : null);

        // Set loading now that button width is set
        setIsLoading(loading);
    }, [loading]);

    const classes = classNames(
        style.button,
        className,
        extend && style.extend,
        enlarge && style.enlarge,
        isLoading && style.loading,
        theme === 'solid-light' && style.solidLight,
        theme === 'outline-light' && style.outlineLight
    );

    const Loader = () => <Loading size={6} />;

    if (link && openInNewTab) {
        return (
            <a href={link} target={openInNewTab ? '_blank' : ''}>
                <button
                    style={{ width: extend ? '100%' : width ? width : 'auto' }}
                    ref={buttonRef}
                    type={type}
                    className={classes}
                    disabled={loading}>
                    {isLoading ? Loader() : text || children}
                    {arrow && <Icon name="angle-right" size="18px" />}
                </button>
            </a>
        );
    }

    return link ? (
        <Link to={link}>
            <button
                style={{ width: extend ? '100%' : width ? width : 'auto' }}
                ref={buttonRef}
                type={type}
                className={classes}
                disabled={loading}>
                {isLoading ? Loader() : text || children}
                {arrow && <Icon name="angle-right" size="18px" />}
            </button>
        </Link>
    ) : (
        <button
            style={{ width: extend ? '100%' : width ? width : 'auto' }}
            ref={buttonRef}
            type={type}
            className={classes}
            onClick={onClick}
            disabled={loading}>
            {isLoading ? Loader() : text || children}
            {arrow && <Icon name="angle-right" size="18px" />}
        </button>
    );
};

export { Button };
