import { AuthReducerState } from '@core/store/reducers/AuthReducer';
import { ChangeNameResponse } from '@core/store/effects/User';

export const changeNameSuccess = (state: AuthReducerState, payload: ChangeNameResponse): AuthReducerState => {
    const { data } = payload;
    return {
        ...state,
        ...(state.user &&
            data && {
                user: {
                    ...state.user,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    displayName: data.displayName
                }
            })
    };
};
